import {
  CONTESTATION_STATUS,
  REASON_MAP,
  useContestationFilter,
} from "@/pages/contestation/contexts/contestation-filter";
import { useModalState } from "@/pages/contestation/hooks";
import { getStatusFormatted } from "@/pages/contestation/utils/get-status-formatted";
import { formatDateToApi } from "@/utils/date";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";
import { Chips } from "@hyperlocal/vital";
import { Button, Checkbox, Select, TextField } from "@hyperlocal/vital2";
import * as Svg from "../svgs";

const TODAY = new Date();

export function Filters() {
  const isMobile = Hooks.useMediaQuery("mobile");
  const { filterState, dispatchFilter } = useContestationFilter();
  const { handleCloseModal, handleOpenModal, modalState } = useModalState();

  function handleClearFilters() {
    dispatchFilter({ type: "CLEAR_FILTERS" });
  }

  function handleClearFiltersMobile() {
    handleClearFilters();
    handleCloseModal();
  }

  return (
    <>
      <div className="mx-4 hidden mobile:block">
        <Chips
          leftIcon={{ name: "FilterFilter" }}
          onClick={handleOpenModal}
          className="mb-4"
        >
          Filtrar contestação
        </Chips>

        <TextField.Root>
          <TextField.Input
            placeholder="Buscar por..."
            value={filterState.search}
            onChange={(event) =>
              dispatchFilter({
                type: "SET_SEARCH",
                payload: { search: event.target.value },
              })
            }
          />
          <TextField.Slot name="SearchNormal" />
        </TextField.Root>
      </div>

      <div
        className="flex flex-1 flex-col rounded-sm bg-neutral-white p-6 mobile:fixed mobile:inset-0 mobile:z-20 mobile:hidden mobile:overflow-y-auto mobile:overflow-x-hidden mobile:rounded-none mobile:p-4 mobile:data-[state=open]:flex"
        data-state={modalState}
      >
        <div className="pb-4 mobile:-mx-4 mobile:flex mobile:justify-between mobile:border-b mobile:border-neutral-lighter mobile:px-4">
          <h4 className="text-sm/md font-bold text-neutral-darkest mobile:leading-default">
            Filtrar contestação
          </h4>

          <Svg.Close
            className="desktop:hidden"
            onClick={handleCloseModal}
            aria-label="Sair do filtro"
          />
        </div>

        <TextField.Root className="mb-6 mobile:hidden">
          <TextField.Input
            placeholder="Buscar..."
            value={filterState.search}
            onChange={(event) =>
              dispatchFilter({
                type: "SET_SEARCH",
                payload: { search: event.target.value },
              })
            }
          />
          <TextField.Slot>
            <Svg.SearchLupe />
          </TextField.Slot>
        </TextField.Root>

        <h4 className="mb-4 text-sm/md font-bold text-neutral-darkest mobile:mt-6 mobile:text-xs">
          Período personalizado
        </h4>

        <div className="mb-6 grid grid-cols-2 gap-4">
          <div>
            <TextField.Label htmlFor="start-date-input">De</TextField.Label>
            <TextField.Root>
              <TextField.Input
                type="date"
                id="start-date-input"
                max={formatDateToApi(filterState.endDate ?? TODAY)}
                value={formatDateToApi(filterState.startDate)}
                onChange={(event) =>
                  dispatchFilter({
                    type: "SET_START_DATE",
                    payload: { startDate: event.target.value },
                  })
                }
              />
              <TextField.Slot name="GeralCalendar" />
            </TextField.Root>
          </div>
          <div>
            <TextField.Label htmlFor="end-date-input">De</TextField.Label>
            <TextField.Root>
              <TextField.Input
                type="date"
                id="end-date-input"
                min={formatDateToApi(filterState.startDate)}
                value={formatDateToApi(filterState.startDate)}
                onChange={(event) =>
                  dispatchFilter({
                    type: "SET_END_DATE",
                    payload: { endDate: event.target.value },
                  })
                }
              />
              <TextField.Slot name="GeralCalendar" />
            </TextField.Root>
          </div>
        </div>

        <Select.Root
          value={filterState.reason}
          onValueChange={(reason) =>
            dispatchFilter({ type: "SET_REASON", payload: { reason } })
          }
        >
          <Select.Label htmlFor="reason-dropdown">
            Motivo da contestação
          </Select.Label>
          <Select.Trigger
            id="reason-dropdown"
            className="gap-2 first:[&>span]:truncate"
          >
            <Select.Value placeholder="Selecione o motivo" />
          </Select.Trigger>
          <Select.Content>
            <Select.Group>
              <Select.GroupTitle>Principais motivos</Select.GroupTitle>
              {Object.entries(REASON_MAP).map(([id, reason]) => (
                <Select.Item value={id} key={id}>
                  {reason}
                </Select.Item>
              ))}
            </Select.Group>
          </Select.Content>
        </Select.Root>

        <h4 className="mb-4 mt-6 text-sm/md font-bold text-neutral-darkest mobile:text-xs">
          Status da contestação
        </h4>

        <div className="mb-8 grid grid-cols-3 whitespace-nowrap mobile:grid-cols-2">
          {CONTESTATION_STATUS.map((status) => (
            <div className="my-2 flex items-center" key={status} title={status}>
              <Checkbox.Slot
                id={status}
                checked={filterState.status.includes(status)}
                onCheckedChange={(checked) => {
                  dispatchFilter({
                    type: "SET_CONTESTATION_STATUS",
                    payload: { status, checked },
                  });
                }}
              />
              <Checkbox.Label htmlFor={status} className="capitalize">
                {getStatusFormatted(status)}s
              </Checkbox.Label>
            </div>
          ))}
        </div>

        <div className="mt-auto">
          <Button.Root
            className="mx-auto w-full mobile:mt-2"
            variant={isMobile ? "primary" : "link"}
            onClick={isMobile ? handleCloseModal : handleClearFilters}
          >
            {isMobile ? "Filtrar" : "Limpar filtros"}
          </Button.Root>

          <Button.Root
            className="mt-2 w-full desktop:hidden"
            variant="link"
            onClick={handleClearFiltersMobile}
          >
            Limpar filtros
          </Button.Root>
        </div>
      </div>
    </>
  );
}
