import { useCallback, useState } from "react";

export const useTooltipHandler = (): [boolean, () => void] => {
  const [tooltipShow, setTooltipShow] = useState(false);

  const handleTooltipShow = useCallback((): void => {
    setTooltipShow(true);

    setTimeout(() => {
      setTooltipShow(false);
    }, 1000);
  }, [setTooltipShow]);

  return [tooltipShow, handleTooltipShow];
};
