import { ReactNode, useState } from "react";
import { PixReceipt } from "@/components/pix-receipt-details/pix-receipt";
import { mockedData } from "@/components/PixDashboard/TransactionsList";
import {
  DetailsSheet,
  TokenSheet,
} from "@/pages/contestation/components/pix-per-period/components/contestation-details-sheet";
import { REASON_MAP } from "@/pages/contestation/contexts/contestation-filter";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getPixRoutesData } from "@hyperlocal/banking-utility";
import { Sheet } from "@hyperlocal/vital2";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { twMerge } from "tailwind-merge";

const routes = getPixRoutesData();

type Steps = "receipt" | "details" | "token";

export const PixReceiptDetails = () => {
  const [step, setStep] = useState<Steps>("receipt");
  const [reason, setReason] = useState<keyof typeof REASON_MAP | null>(null);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const transaction = mockedData[1];

  const movement = searchParams.get("movement");

  function goToNextStep(reason: keyof typeof REASON_MAP) {
    setStep("token");
    setReason(reason);
  }

  function goToTokenStep() {
    setStep("token");
  }

  function goToInitialStep() {
    setStep("details");
  }

  const steps: Record<Steps, { title: string; component: ReactNode }> = {
    details: {
      title: "Detalhe da contestação",
      component: (
        <DetailsSheet
          details={{
            amount: transaction.amount,
            id: transaction.details.externalId,
            institution: transaction.details.bankName,
            person: transaction.details.name,
            pixKey: transaction.partnerKey,
            reason,
            transferDate: transaction.date,
            status: "PENDING",
            requestDate: new Date().toISOString(),
          }}
          goToNextStep={goToTokenStep}
        />
      ),
    },
    receipt: {
      title: "Comprovante",
      component: (
        <Parcel
          config={() => System.import("@hyperlocal/banking-receipt")}
          mountParcel={mountRootParcel}
          receiptId={params.id}
          receiptType={"Pix"}
          movement={movement}
        />
      ),
    },
    token: {
      title: "Validação",
      component: (
        <TokenSheet
          id={transaction.details.externalId}
          goToInitialStep={goToInitialStep}
        />
      ),
    },
  };

  function onOpenChange(open: boolean) {
    if (open) {
      setStep("receipt");
    } else {
      navigate(routes.pix);
    }
  }

  return (
    <Sheet.Root onOpenChange={onOpenChange} defaultOpen>
      <Sheet.Content
        className={twMerge("mobile:p-4", step === "receipt" && "!p-0")}
      >
        {step === "receipt" ? (
          <Parcel
            config={() => System.import("@hyperlocal/banking-receipt")}
            mountParcel={mountRootParcel}
            receiptId={params.id}
            receiptType={"Pix"}
            movement={movement}
          />
        ) : (
          <>
            <Sheet.Header>
              <Sheet.Title className="text-2xl/md font-bold text-neutral-darkest mobile:text-sm/default">
                {steps[step].title}
              </Sheet.Title>
            </Sheet.Header>

            {steps[step].component}
          </>
        )}
      </Sheet.Content>
    </Sheet.Root>
  );
};
