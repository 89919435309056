import { Button } from "@hyperlocal/vital";
import { useEffect, useState } from "react";
import * as S from "./styles";

interface TimerProps {
  resendToken: () => void;
}

const INITIAL_TIME = 30;

export function Timer({ resendToken }: TimerProps) {
  const [remainingTime, setRemainingTime] = useState(INITIAL_TIME);

  const timeIsUp = remainingTime === 0;

  const resend = () => {
    setRemainingTime(INITIAL_TIME);
    resendToken();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prev) => prev - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [remainingTime]);

  return (
    <S.Container>
      <S.Message>Não recebeu o código?</S.Message>
      {timeIsUp ? (
        <Button variant="link" onClick={resend}>
          Reenviar código
        </Button>
      ) : (
        <S.MessageTiming>
          Espere {remainingTime} segundos para pedir.
        </S.MessageTiming>
      )}
    </S.Container>
  );
}
