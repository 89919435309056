import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { IDrawerContext, IDrawerOptions, IModalProps } from "./types";

export const DrawerContext = createContext({} as IDrawerContext);

type DrawerContextProviderProps = {
  children: ReactNode;
};

export const DrawerContextProvider = ({
  children,
}: DrawerContextProviderProps) => {
  const [drawer, setDrawerContent] = useState({} as IDrawerOptions);
  const [modal, setModalContent] = useState({} as IModalProps);

  const handleDrawerContent = useCallback(
    (drawerInfo: IDrawerOptions) => {
      setDrawerContent({
        ...drawer,
        ...drawerInfo,
      });
    },
    [drawer, setDrawerContent],
  );

  const handleModalContent = useCallback(
    (modalInfo: IModalProps) => {
      setModalContent({
        ...modal,
        ...modalInfo,
        onClose: () => setModalContent({ isOpen: false }),
      });
    },
    [modal, setModalContent],
  );

  const contextValue: IDrawerContext = useMemo(() => {
    return {
      handleDrawerContent,
      drawerContent: drawer,
      handleModalContent,
      modalContent: modal,
    };
  }, [drawer, handleDrawerContent, modal, handleModalContent]);

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const value = useContext(DrawerContext);

  return value;
};
