// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency, getPixRoutesData } from "@hyperlocal/banking-utility";
import { Link } from "react-router-dom";
import * as S from "./TransactionItem.styles";
import { handleDate, ListItem } from "./TransactionsList";

type TransactionItemProps = {
  transaction: ListItem;
};

type TransactionItemDetailProps = {
  label: string;
  value: string;
  direction?: "column" | "row";
};

export const TransactionItemDetail = (props: TransactionItemDetailProps) => {
  const { label, value, direction = "row" } = props;

  return (
    <S.TransactionItemDetail direction={direction}>
      <S.TransactionItemLabel>{label}</S.TransactionItemLabel>
      <S.TransactionItemValue>{value}</S.TransactionItemValue>
    </S.TransactionItemDetail>
  );
};

const routes = getPixRoutesData();

export const TransactionItem = ({ transaction }: TransactionItemProps) => {
  const isOutcome = transaction.Movement === "OUT";

  return (
    <S.TransactionItem>
      <Link
        to={`${routes.pix}/${transaction.Id}?movement=${transaction.Movement}`}
      >
        <S.Card className="transition-shadow">
          <S.Avatar icon="MoneyPix" type={isOutcome ? "outcome" : "income"} />

          <S.TransactionItemContent className="items-center">
            <S.TransactionItemInfo position="start">
              <S.TransactionItemHeading type="primary">
                {isOutcome ? "Pix Enviado" : "Pix Recebido"}
              </S.TransactionItemHeading>

              {/* <S.TransactionItemHeading>
                {transaction.details.name}
              </S.TransactionItemHeading> */}
            </S.TransactionItemInfo>

            <S.TransactionItemInfo position="end">
              <S.TransactionItemHeading>
                {handleDate(transaction.CreatedAt, "hour")}
              </S.TransactionItemHeading>

              <S.TransactionItemAmount type={isOutcome ? "outcome" : "income"}>
                {isOutcome && "-"}
                {formatCurrency(transaction.Value)}
              </S.TransactionItemAmount>
            </S.TransactionItemInfo>
          </S.TransactionItemContent>
        </S.Card>
      </Link>
    </S.TransactionItem>
  );
};
