// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GroupBy<T> = keyof T | ((item: T) => any);

export function groupBy<T>(
  array: T[],
  key: GroupBy<T>,
): { key: string; items: T[] }[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const groups = new Map<any, T[]>();
  array?.forEach((item) => {
    const groupKey = typeof key === "function" ? key(item) : item[key];
    const formattedGroupKey = new Date(groupKey).toDateString();
    const group = groups.get(formattedGroupKey);
    if (!group) {
      groups.set(formattedGroupKey, [item]);
    } else {
      group.push(item);
    }
  });
  return Array.from(groups.entries(), ([key, items]) => ({ key, items }));
}
