import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useReactToPrint } from "react-to-print";
import * as S from "./styles";
import { IPrintContext, PrintContent } from "./types";

export const PrintContext = createContext({} as IPrintContext);

type PrintContextProviderProps = {
  children: ReactNode;
};

export const PrintContextProvider = ({
  children,
}: PrintContextProviderProps) => {
  const [printContent, setPrintContent] = useState<PrintContent>(
    {} as PrintContent,
  );
  const printable = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printable.current,
    onAfterPrint: () => {
      printable.current.style.display = "none";
    },
    onBeforeGetContent: () => {
      printable.current.style.display = "block";
    },
    pageStyle: "display: block",
  });

  const togglePrintable = useCallback(() => {
    if (printable.current === null) {
      return;
    }

    printable.current.style.display = "block";
    handlePrint();
  }, [handlePrint]);

  const print = useCallback(
    ({ content }: PrintContent) => {
      setPrintContent({ content });
      setTimeout(() => {
        togglePrintable();
      }, 500);
    },
    [togglePrintable],
  );

  const contextValue: IPrintContext = useMemo(() => {
    return {
      print,
    };
  }, [print]);

  return (
    <PrintContext.Provider value={contextValue}>
      {children}
      <S.InvisiblePrint ref={printable} id="printable">
        {printContent?.content}
      </S.InvisiblePrint>
    </PrintContext.Provider>
  );
};

export const usePrint = () => {
  const value = useContext(PrintContext);

  return value;
};
