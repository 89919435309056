import { ReactNode, useCallback, useState } from "react";
import { Modal } from "@hyperlocal/vital";

export type DrawerOptions = {
  title: string;
  open: boolean;
  content: JSX.Element;
};

type ModalInfo = Partial<React.ComponentProps<typeof Modal>>;

export type ModalProps = Omit<ModalInfo, "variant"> & {
  variant?: "custom" | "button";
  children?: ReactNode;
};

export type UseDisclosure = {
  drawerContent: DrawerOptions;
  handleDrawerContent: (drawerInfo: DrawerOptions) => void;
  modalContent: ModalProps;
  handleModalContent: (modalInfo: ModalProps) => void;
};

export function useDisclosure(): UseDisclosure {
  const [drawer, setDrawerContent] = useState<DrawerOptions | null>(null);
  const [modal, setModalContent] = useState<ModalProps | null>(null);

  const handleDrawerContent = useCallback(
    (drawerInfo: DrawerOptions) => {
      setDrawerContent((prev) => ({ ...prev, ...drawerInfo }));
    },
    [drawer, setDrawerContent],
  );

  const handleModalContent = useCallback(
    (modalInfo: ModalProps) => {
      setModalContent((prev) => ({
        ...prev,
        ...modalInfo,
        onClose: () => setModalContent({ isOpen: false }),
      }));
    },
    [modal, setModalContent],
  );

  return {
    drawerContent: drawer,
    handleDrawerContent,
    modalContent: modal,
    handleModalContent,
  };
}
