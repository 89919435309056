import {
  Hooks,
  getPixRoutesData,
  getVertical,
  globalQueryClient,
  themeProvider,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Provider } from "@hyperlocal/vital";
import { Toaster } from "@hyperlocal/vital2";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./App.styles";
import { AppProvider } from "./context";
import { Routes } from "./routes";

function App() {
  const routes = getPixRoutesData();
  const theme = getVertical();
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <Provider theme={theme}>
      <ThemeProvider theme={themeProvider}>
        <BrowserRouter>
          <AppProvider>
            <GlobalStyles />

            <QueryClientProvider client={globalQueryClient}>
              <ReactQueryDevtools />
              <Routes defaultRoutes={routes} />

              <Toaster position={isMobile ? "bottom-center" : "top-right"} />
            </QueryClientProvider>
          </AppProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
