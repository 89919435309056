import styled, { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @media print {
    * {
      -webkit-print-color-adjust: exact;
    }
  }
`;
export const ContainerPrint = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100vh;
    padding: 50px 0px 120px;
    inset: 0;

    font-family: ${theme.fonts.base};

    @media print {
      -webkit-print-color-adjust: exact;
    }

    & > header {
      display: flex;

      img {
        width: 122px;
        height: 122px;
      }
    }

    & > footer {
      & > h3 {
        margin-top: 26px;
      }
    }
  `}
`;

export const LogoWrapper = styled.div`
  svg {
    max-width: 180px;
  }
`;

export const InfoWrapper = styled.div`
  margin-bottom: 26px;
  margin-left: 15px;
`;

export const BoldText = styled.h5`
  margin-top: 20px;
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.x2s};
    font-weight: ${theme.fontWeights.bold};
  `}
`;

export const OrdinaryInformation = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.x3s};
    font-weight: ${theme.fontWeights.medium};
    margin-top: 4px;
  `}
`;

export const WarningParagraph = styled.div`
  padding: 26px 15px;
`;

export const ContactSection = styled.div`
  display: flex;
  padding: 19px 0px 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  > h3 {
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
  }
  > p {
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    word-break: break-all;
  }
  hr {
    height: 36px;
    width: 1px;
    background: ${({ theme }) => theme.palette.neutral.main};
    outline: none;
    border: none;
  }
`;

export const Blank = styled.div`
  flex: 1;
`;

export const DataList = styled.dl`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: ${({ theme }) => theme.spacings.squished.x2s};
  flex-wrap: wrap;
  &.summary {
    width: 100%;
    gap: 4px;
    dt,
    dd {
      strong {
        color: ${({ theme }) => theme.palette.status.warning.warningDefault};
        font-weight: 400;
      }
      width: 100%;
      & > span {
        width: 100%;
      }
    }
  }

  dt,
  dd {
    font-size: ${({ theme }) => theme.fontSizes.x2s};
    font-family: ${({ theme }) => theme.fonts.base};
    line-height: ${({ theme }) => theme.lineHeights.md};
    max-width: 100%;
    word-wrap: break-word;
    & > span {
      width: 109px;
      line-height: 150%;
    }
  }

  @media print {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 16px;
    &.summary {
      dt,
      dd {
        width: auto;
        & > span {
          width: auto;
        }
      }
    }
  }
`;

export const DataTitle = styled.dt`
  color: ${({ theme }) => theme.palette.neutral.darker};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  @media print {
    width: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const DataDescription = styled.dd`
  color: ${({ theme }) => theme.palette.neutral.dark};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  @media print {
    width: max-content;
    word-wrap: break-word;
  }
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
`;
