import { PixRoutesPath } from "@/@types/routes";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getPixRoutesData } from "@hyperlocal/banking-utility";
import { Breadcrumb as VitalBreadcrumb } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";

export interface BreadcrumbItem {
  name: string;
  path: PixRoutesPath;
  active: boolean;
}

interface BreadcrumbProps {
  breadcrumbs: BreadcrumbItem[];
}

const routes = getPixRoutesData();

export function Breadcrumb({ breadcrumbs }: BreadcrumbProps) {
  return (
    <VitalBreadcrumb.Root className="mobile:hidden">
      <VitalBreadcrumb.List>
        <VitalBreadcrumb.ListItem>
          <VitalBreadcrumb.Home href={routes.dashboard} className="ml-0" />
        </VitalBreadcrumb.ListItem>

        {breadcrumbs.map((item) => (
          <VitalBreadcrumb.ListItem key={item.name}>
            <VitalBreadcrumb.Separator />
            <VitalBreadcrumb.Anchor
              href={routes[item.path]}
              active={item.active}
              className={twMerge(item.active && "pointer-events-none")}
            >
              {item.name}
            </VitalBreadcrumb.Anchor>
          </VitalBreadcrumb.ListItem>
        ))}
      </VitalBreadcrumb.List>
    </VitalBreadcrumb.Root>
  );
}
