import {
  queries,
  requestBalance,
  useAccountStore,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { useQuery } from "@tanstack/react-query";

const { balanceKeys } = queries;

export function useBalance() {
  const { currentAccountId } = useAccountStore();

  return useQuery({
    queryKey: balanceKeys.getBalance({ accountId: currentAccountId }),
    queryFn: () => requestBalance(),
    staleTime: 1000 * 30, // 30 segundos (cache entre refetch's)
    select: (pixResponse) => pixResponse.Balance,
    refetchOnWindowFocus: true,
  });
}
