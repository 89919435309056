import { useState } from "react";
import { useBalance } from "@/api/use-balance";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";

const SECRET_VALUE = "••••••";

export interface BalanceSectionProps {
  title: string;
  value: number;
  isLoading: boolean;
  isValueVisible: boolean;
}

export function BalanceSection({
  title,
  value,
  isLoading,
  isValueVisible,
}: BalanceSectionProps) {
  const balanceValue = isValueVisible
    ? formatCurrency(value ?? 0, {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : SECRET_VALUE;

  const balanceValueTitle = isLoading
    ? "Buscando saldo..."
    : `R$ ${balanceValue}`;

  return (
    <div className="flex-1 overflow-hidden mobile:flex mobile:items-center mobile:gap-2">
      <h4 className="mb-2 text-xs/md text-neutral-darkest mobile:mb-0 mobile:text-x2s/sm mobile:text-neutral-darker">
        {title}
      </h4>

      <span
        className="flex items-center gap-2 text-primary-main mobile:gap-1 mobile:text-neutral-darker"
        title={balanceValueTitle}
      >
        <small className="text-xs/md font-bold">R$</small>

        {isLoading ? (
          <div className="h-8 w-24 animate-pulse rounded-sm bg-neutral-lighter mobile:h-6 mobile:w-12" />
        ) : (
          <b className="truncate text-lg/default font-bold mobile:text-xs/md">
            {balanceValue}
          </b>
        )}
      </span>
    </div>
  );
}

export function Balance() {
  const [isValueVisible, setIsValueVisible] = useState(false);
  const { data: balance, isLoading, isError } = useBalance();

  function handleToggleValueVisibility() {
    setIsValueVisible((oldValue) => !oldValue);
  }

  return (
    <div className="flex items-center gap-8 rounded-sm bg-neutral-white px-6 py-4 mobile:bg-neutral-lighter mobile:p-4 pix-balance-css-wildcard">
      <BalanceSection
        title="Saldo em conta"
        value={isError ? 0 : balance}
        isLoading={isLoading}
        isValueVisible={isValueVisible}
      />

      <button
        className="disabled:opacity-30"
        onClick={handleToggleValueVisibility}
        disabled={isLoading}
        aria-label="Esconder valor"
      >
        <Icon
          name={isValueVisible ? "ComputersEye" : "ComputersEyeSlash"}
          fill="#000000"
          width="24"
          height="24"
        />
      </button>
    </div>
  );
}
