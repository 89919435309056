/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  getPixRoutesData,
  Validator,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button, Card, IconButton } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
// import NewKey from "../NewKey";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { keysTypes } from "../../utils/keyTypes";
import MyKeys from "../MyKeys";
import { IMyKeys } from "../MyKeys/types";
import * as Styles from "./styles";

export function MyKeysDrawer({ handleModal }: IMyKeys) {
  return (
    <Styles.PixDrawer>
      <MyKeys handleModal={handleModal} />
    </Styles.PixDrawer>
  );
}

export const PixDrawer = ({
  clipboardState,
  getPixCopiedText,
  handleCopiedPix,
}) => {
  const { clipboard, error } = clipboardState;
  const { checkPixKey } = Validator;
  return (
    <Styles.PixDrawer>
      {getPixCopiedText(checkPixKey(clipboard))}
      {!!error && <Styles.ErrorFeedback>{error}</Styles.ErrorFeedback>}
      <nav>
        <Card>
          <div>
            <IconButton icon="MoneyPix" />
            <div>
              <span>{checkPixKey(clipboard)}</span>
              <span>{clipboard}</span>
            </div>
          </div>
          <Icon name="ArrowArrowNoLineRight" />
        </Card>
      </nav>
      <Button onClick={handleCopiedPix}>Fazer Pix</Button>
    </Styles.PixDrawer>
  );
};

export const PixMigrationDrawer = () => {
  const navigate = useNavigate();
  return (
    <Styles.PixDrawer>
      Você tem algumas solicitações de portabilidades e/ou reivindicações
      pendentes.
      <Button
        onClick={() => navigate(getPixRoutesData().pixMyKeys)}
        variant="secondary"
        className="myKeysButton"
      >
        Minhas Chaves
      </Button>
    </Styles.PixDrawer>
  );
};

export const PixModal = ({
  clipboardState,
  getPixCopiedText,
  handleCopiedPix,
}) => {
  const { clipboard = "14310624642", error } = clipboardState;
  const { checkPixKey } = Validator;
  const { palette } = useTheme();

  const pixType = checkPixKey(clipboard);

  const pixDictionary =
    keysTypes.find((key) => key.title === pixType) ||
    keysTypes[keysTypes.length - 1];

  return (
    <Styles.PixModal>
      {getPixCopiedText(pixType)}
      <Card>
        <Icon color={palette.neutral.darkest} name={pixDictionary.icon} />
        <div className="pixData">
          <p>{pixDictionary.title}</p>
          <p>{clipboard}</p>
        </div>
      </Card>
      <Button variant="primary" onClick={handleCopiedPix}>
        Fazer Pix
      </Button>
      {!!error && <Styles.ErrorFeedback>{error}</Styles.ErrorFeedback>}
    </Styles.PixModal>
  );
};
