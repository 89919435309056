// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@hyperlocal/banking-utility";
import { AxiosRequestHeaders, AxiosResponse } from "axios";
import {
  GetAllTransactions,
  IApproveTransaction,
  ICancelMigration,
  IChangeDefaultKey,
  ICreateKey,
  ICreateQR,
  IDebit,
  IDeleteKey,
  IGetKeys,
  IGetQR,
  IMigrate,
  IPixTransactionsByStatus,
  IResponseMigration,
  IRevetDebit,
  TransactionListResponse,
} from "./types";

const api = API.pix;

const defaultHeaders = {
  ...(API.pix.defaults.headers as unknown as AxiosRequestHeaders),
};

export type GetAllTransactionsParams = {
  accountId: string;
  userId: string;
  startDate?: string;
  endDate?: string;
};

export type IGetAllTransactionsByStatus = GetAllTransactionsParams & {
  status:
    | "ALL"
    | "PENDING_APPROVAL"
    | "APPROVED"
    | "DENIED"
    | "PROCESSED"
    | "ERROR";
};

export const apis = {
  create: async (body: ICreateKey, accountId: string, userId: string) => {
    const response = await api.post(`/api/Pix/${accountId}/create`, body, {
      headers: {
        ...defaultHeaders,
        userId,
      },
    });

    return response;
  },

  listKeys: async (accountId: string, userId: string) => {
    const response = await api.get<IGetKeys[]>(
      `/api/Pix/${accountId}/listKeys`,
      {
        headers: {
          ...defaultHeaders,
          userId,
        },
      },
    );

    return response;
  },

  delete: async (body: IDeleteKey, accountId: string, userId: string) => {
    const response = await api.delete(`/api/Pix/${accountId}`, {
      headers: {
        ...defaultHeaders,
        userId,
      },
      data: { ...body },
    });

    return response;
  },

  getQR: async (body: IGetQR, accountId: string, userId: string) => {
    const response = await api.post(
      `/api/Pix/${accountId}/qrcode/decode`,
      body,
      {
        headers: {
          ...defaultHeaders,
          userId,
        },
      },
    );

    return response;
  },

  createQR: async (body: ICreateQR, accountId: string, userId: string) => {
    const response = await api.post(
      `/api/Pix/${accountId}/qrcode/static`,
      body,
      {
        headers: {
          ...defaultHeaders,
          userId,
        },
      },
    );

    return response;
  },

  migrate: async (body: IMigrate, accountId: string, userId: string) => {
    const response = await api.post(
      `/api/Pix/${accountId}/claims/create`,
      body,
      {
        headers: {
          ...defaultHeaders,
          userId,
        },
      },
    );

    return response;
  },

  cancelMigration: async (
    body: ICancelMigration,
    accountId: string,
    userId: string,
  ) => {
    const response = await api.post(
      `/api/Pix/${accountId}/claims/cancel`,
      body,
      {
        headers: {
          ...defaultHeaders,
          userId,
        },
      },
    );

    return response;
  },

  responseMigration: async (
    body: IResponseMigration,
    accountId: string,
    userId: string,
  ) => {
    const response = await api.post(
      `/api/Pix/${accountId}/claims/received/response`,
      body,
      {
        headers: {
          ...defaultHeaders,
          userId,
        },
      },
    );

    return response;
  },

  listAllClaimsReceived: async (accountId: string, userId: string) => {
    const response = await api.get(
      `/api/Pix/${accountId}/claims/getAllClaimsReceived`,
      {
        headers: {
          ...defaultHeaders,
          userId,
        },
      },
    );

    return response;
  },

  listAllClaimsRequested: async (accountId: string, userId: string) => {
    const response = await api.get(
      `/api/Pix/${accountId}/claims/getAllClaimsRequested`,
      {
        headers: {
          ...defaultHeaders,
          userId,
        },
      },
    );

    return response;
  },

  debit: async (body: IDebit, accountId: string, userId: string) => {
    const response = await api.post(
      `/api/Pix/${accountId}/transaction/debit`,
      body,
      {
        headers: {
          ...defaultHeaders,
          userId,
        },
      },
    );

    return response;
  },

  revetDebit: async (
    body: IRevetDebit,
    accountId: string,
    transactionId: string,
    userId: string,
  ) => {
    const response = await api.post(
      `/api/Pix/${accountId}/transaction/${transactionId}/reversal`,
      body,
      {
        headers: {
          ...defaultHeaders,
          userId,
        },
      },
    );

    return response;
  },

  listContacts: async (accountId: string, userId: string) => {
    const response = await api.get(`/api/Pix/${accountId}/contact/all`, {
      headers: {
        ...defaultHeaders,
        userId,
      },
    });

    return response;
  },

  changeDefaultKey: async (
    body: IChangeDefaultKey,
    accountId: string,
    userId: string,
  ) => {
    const response = await api.patch(`/api/Pix/${accountId}/key/edit`, body, {
      headers: {
        ...defaultHeaders,
        userId,
      },
    });

    return response;
  },

  listTransaction: async (
    accountId: string,
    startDate: string,
    endDate: string,
    userId: string,
  ): Promise<AxiosResponse<TransactionListResponse>> => {
    const url = `/api/Pix/${accountId}/transaction/statement?startDate=${startDate}&endDate=${endDate}`;

    const response = await api.get(url, {
      headers: {
        ...defaultHeaders,
        userId,
      },
    });

    return response;
  },

  getKey: async (key: string, accountId: string, userId: string) => {
    const response = await api.post(
      `/api/Pix/${accountId}/find`,
      { key },
      {
        headers: {
          ...defaultHeaders,
          userId,
        },
      },
    );

    return response;
  },

  getAllContacts: async (accountId: string, userId: string) => {
    const response = await api.get(`/api/Pix/${accountId}/contact/all`, {
      headers: {
        ...defaultHeaders,
        userId,
      },
    });

    return response;
  },

  getAllTransactionsByStatus: async (
    params: IGetAllTransactionsByStatus,
  ): Promise<AxiosResponse<IPixTransactionsByStatus[]>> => {
    const url = new URL(
      `/api/Pix/${params.accountId}/transaction/getAllTransactionsByStatus`,
      api.defaults.baseURL,
    );

    url.searchParams.append("Status", params.status);
    url.searchParams.append("startDate", params.startDate || "");
    url.searchParams.append("endDate", params.endDate || "");

    const response = await api.get(url.toString(), {
      headers: {
        ...defaultHeaders,
        userId: params.userId,
      },
    });

    return response;
  },

  getAllTransactions: async (
    params: GetAllTransactionsParams,
  ): Promise<GetAllTransactions> => {
    const url = new URL(
      `/api/Pix/${params.accountId}/transaction/statement`,
      api.defaults.baseURL,
    );

    url.searchParams.append("startDate", params.startDate || "");
    url.searchParams.append("endDate", params.endDate || "");

    const { data } = await api.get<GetAllTransactions>(url.toString(), {
      headers: {
        ...defaultHeaders,
        userId: params.userId,
      },
    });

    return data;
  },

  approveTransaction: async (
    body: IApproveTransaction,
    accountId: string,
    userId: string,
  ) => {
    const url = `/api/Pix/${accountId}/transaction/pending/response`;

    const response = await api.post(url, body, {
      headers: {
        ...defaultHeaders,
        userId,
      },
    });

    return response;
  },
};
