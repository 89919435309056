import styled from "styled-components";

export const Feedback = styled.p`
  width: 100%;
  display: flex;
  height: 85px;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.palette.neutral.main};
  font-size: ${({ theme }) => theme.fontSizes.x3s};
`;
