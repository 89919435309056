// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { setCreateKey } from "@hyperlocal/banking-utility";
import { Card } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { IGetKeys } from "../../services/types";
import { hasKeyType, keysTypes } from "../../utils";
import * as S from "./styles";

type SelectKeyProps = {
  keys: IGetKeys[];
  goToToken?: () => void;
  goToInsertKey: () => void;
};

type HandleSelectKeyParams = {
  type: string;
  title: string;
  mask: string;
  key?: string;
};

export function SelectKey({ keys, goToInsertKey, goToToken }: SelectKeyProps) {
  function handleSelectKey({ mask, title, type }: HandleSelectKeyParams) {
    const createKeyData: HandleSelectKeyParams = {
      type,
      title,
      mask,
    };

    if (type === "EVP") {
      createKeyData.key = "1";

      return goToToken?.();
    }

    setCreateKey(createKeyData);
    goToInsertKey();
  }

  return (
    <S.Main>
      <h2>Escolha a chave que deseja cadastrar</h2>
      <div className="wrapper">
        {keysTypes?.map((key) => {
          const isCPFOrCNPJ = ["CPF", "CNPJ"].includes(key.type);
          const hasNoKeyType = !hasKeyType(keys, key.type);

          if (key.title === "Código Pix") return null;

          if ((isCPFOrCNPJ && hasNoKeyType) || !isCPFOrCNPJ) {
            return (
              <nav
                key={key.type}
                onClick={() =>
                  handleSelectKey({
                    mask: key.mask,
                    title: key.title,
                    type: key.type,
                  })
                }
              >
                <Card>
                  <div>
                    <Icon name={key.icon} />
                    <p>
                      <span>{key.title}</span>
                    </p>
                  </div>
                  <Icon name="ArrowArrowNoLineRight" />
                </Card>
              </nav>
            );
          }

          return null;
        })}
      </div>
    </S.Main>
  );
}
