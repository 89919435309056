import { MyKeysResponse } from "@/components/MyKeys";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API, useAccountStore } from "@hyperlocal/banking-utility";
import { useQuery } from "@tanstack/react-query";
import EmptyKeysState from "../EmptyKeysState";
import MainKeyInfo from "../MainKeyInfo";
import { Feedback } from "./styles";

type DashboardProps = {
  handleOpenNewKeysDrawer: () => void;
};

export function getKeyInOrder(keys: MyKeysResponse[]) {
  const document = keys.find((key) => key.keyType === "NationalRegistration");
  if (document) return document;

  const number = keys.find((key) => key.keyType === "PhoneNumber");
  if (number) return number;

  const email = keys.find((key) => key.keyType === "Email");
  if (email) return email;

  return keys[0];
}

function Dashboard({ handleOpenNewKeysDrawer }: DashboardProps) {
  const accountId = useAccountStore((state) => state.currentAccountId);
  const { data: keys = [], isLoading } = useQuery({
    queryKey: ["pixKeys", accountId],
    enabled: !!accountId,
    queryFn: async () => {
      try {
        const response = await API.pix.get<MyKeysResponse[]>(
          "/api/v1/pix/Key/allKeys",
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    refetchOnWindowFocus: true,
  });

  const key = getKeyInOrder(keys);

  if (isLoading) {
    return <Feedback>Carregando...</Feedback>;
  }

  if (key) {
    return <MainKeyInfo defaultKey={key} />;
  }

  return <EmptyKeysState handleOpenNewKeysDrawer={handleOpenNewKeysDrawer} />;
}

export default Dashboard;
