import { ReactNode } from "react";
import { DrawerContextProvider } from "./Drawer";
import { PrintContextProvider } from "./Print";
import { RoutesContextProvider } from "./routes";

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <RoutesContextProvider>
      <DrawerContextProvider>
        <PrintContextProvider>{children}</PrintContextProvider>
      </DrawerContextProvider>
    </RoutesContextProvider>
  );
};
