import { Avatar as VitalAvatar, Card as VitalCard } from "@hyperlocal/vital";
import styled from "styled-components";

export const List = styled.ul`
  width: 100%;
  height: 100%;

  overflow-x: auto;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.inset.md};

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.palette.neutral.light};
  }
`;

export const ListHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: ${({ theme }) => theme.fonts.base};
  font-style: normal;
  & > h5 {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.md};
    color: ${({ theme }) => theme.palette.neutral.darkest};
  }

  & > small {
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.x2s};
    line-height: ${({ theme }) => theme.lineHeights.sm};
    color: ${({ theme }) => theme.palette.neutral.dark};
  }
`;

export const Feedback = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.palette.neutral.main};
  font-size: ${({ theme }) => theme.fontSizes.x3s};
`;
