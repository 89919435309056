import React from "react";
import { Button } from "@hyperlocal/vital";
import { useLocation, useNavigate } from "react-router-dom";
import { useRoutes } from "../../context/routes";
import * as S from "./styles";

type EmptyKeysStateProps = {
  handleOpenNewKeysDrawer: () => void;
};

function EmptyKeysState({ handleOpenNewKeysDrawer }: EmptyKeysStateProps) {
  const { routes } = useRoutes();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    if (pathname.includes(routes.dashboard)) {
      return navigate(routes.pix + "?newKey=open");
    }

    handleOpenNewKeysDrawer();
  };

  return (
    <S.Container>
      <S.InfoText>
        Chave Pix não encontrada, <br /> cadastre uma nova.
      </S.InfoText>

      <Button variant="link" onClick={handleClick}>
        Cadastrar chave Pix
      </Button>
    </S.Container>
  );
}

export default EmptyKeysState;
