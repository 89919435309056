import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export function useUrlDisclosure(key: string, initialState = false) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(
    searchParams.get(key) === "open" || initialState,
  );

  const updateSearchParams = useCallback(
    (newParams: URLSearchParams) => {
      setIsOpen(newParams.get(key) === "open" || initialState);
      setSearchParams(newParams);
    },
    [key, initialState, setSearchParams],
  );

  const open = useCallback(() => {
    updateSearchParams(new URLSearchParams({ [key]: "open" }));
  }, [key, updateSearchParams]);

  const close = useCallback(() => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete(key);
    updateSearchParams(newParams);
  }, [key, searchParams, updateSearchParams]);

  const toggle = useCallback(() => {
    const newParams = new URLSearchParams(searchParams);
    if (isOpen) {
      newParams.delete(key);
    } else {
      newParams.set(key, "open");
    }
    updateSearchParams(newParams);
  }, [isOpen, key, searchParams, updateSearchParams]);

  useEffect(() => {
    setIsOpen(searchParams.get(key) === "open" || initialState);
  }, [key, initialState, searchParams]);

  return { open, close, toggle, isOpen };
}
