import { Tooltip as DsTooltip } from "@hyperlocal/vital";
import styled, { css } from "styled-components";

export const Tooltip = styled(DsTooltip)<{ isShown: boolean }>`
  ${({ isShown }) => css`
    width: fit-content !important;
    position: absolute !important;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0 !important;
    right: 0 !important;
    top: -60px !important;
    transition: all 0.3s !important;
    opacity: ${isShown ? 1 : 0} !important;
  `}
`;
