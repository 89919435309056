import { createContext, ReactNode, useContext, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { PixRoutes } from "@hyperlocal/banking-utility";

type RoutesContextProps = {
  children: ReactNode;
};

type RoutesContextType = {
  routes: PixRoutes;
  setRoutes: (routes: PixRoutes) => void;
};

export const RoutesContext = createContext<RoutesContextType>(
  {} as RoutesContextType,
);

export const RoutesContextProvider = ({ children }: RoutesContextProps) => {
  const [routes, setRoutes] = useState<PixRoutes>();

  return (
    <RoutesContext.Provider value={{ routes, setRoutes }}>
      {children}
    </RoutesContext.Provider>
  );
};

export const useRoutes = () => {
  const value = useContext(RoutesContext);

  return value;
};
