import styled, { css } from "styled-components";

export const WrapperQRIcon = styled.div`
  ${({ theme }) => css`
    padding: 16px 0 35px 0;
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    font-family: ${theme.fonts.base};
    & > span {
      margin-bottom: 18px;
    }
    & > nav > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 11px;
        & > div > p {
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
        }
        & > div > h3 {
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
        }
      }
      & svg {
        width: 24px;
        height: 24px;
        fill: ${theme.palette.neutral.darkest};
      }
    }
  `}
`;
