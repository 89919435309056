import { ContestationStatus } from "@/pages/contestation/contexts/contestation-filter/types";

export function getStatusFormatted(status: ContestationStatus) {
  const statusMapped: Record<ContestationStatus, string> = {
    CANCELED: "cancelada",
    PENDING: "pendente",
  };

  return statusMapped[status];
}
