import { createContext, useContext, useMemo, useReducer } from "react";
import { Action, Context, State } from "@/context/Pix/types";

const initialState: State = {
  newPixKey: "",
  MFACode: "",
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_MFA_CODE":
      return { ...state, MFACode: action.payload.MFACode };
    case "SET_NEW_PIX_KEY":
      return { ...state, newPixKey: action.payload.newPixKey };
    case "CLEAR_FILTERS":
      return initialState;
    default:
      return state;
  }
}

const PixKeysContext = createContext<Context>({
  filterState: initialState,
  dispatchFilter: () => null,
});

export function PixKeysProvider({ children }: { children: React.ReactNode }) {
  const [filterState, dispatchFilter] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({ filterState, dispatchFilter }),
    [filterState, dispatchFilter],
  );

  return (
    <PixKeysContext.Provider value={value}>{children}</PixKeysContext.Provider>
  );
}

export function usePixKeysContext() {
  const context = useContext(PixKeysContext);
  if (!context) {
    throw new Error(
      "PixKeysContextContext must be used within PixKeysContextProvider",
    );
  }
  return context;
}
