import React from "react";
import * as S from "./styles";

interface TooltipProps {
  isShown: boolean;
  message: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ isShown, message }) => {
  return (
    <S.Tooltip isShown={isShown} arrow="bottom">
      {message}
    </S.Tooltip>
  );
};
