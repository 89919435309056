import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem 0;
  width: 100%;
`;

export const InfoText = styled.p``;

export const IconWrapper = styled.div`
  width: 100%;
  max-width: 85px;
  height: 85px;
  background-color: ${({ theme }) => theme.palette.secondary.lighter};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 16px;
`;
