import { createContext, useContext, useMemo, useReducer } from "react";
import {
  Action,
  Context,
  State,
} from "@/pages/contestation/contexts/contestation-filter/types";
import {
  getEndDate,
  getStartDate,
  handleEndDate,
  handleStartDate,
  handleStatus,
} from "@/pages/contestation/contexts/contestation-filter/utils";

export const CONTESTATION_STATUS = ["PENDING", "CANCELED"] as const;
export const REASON_MAP = {
  PRODUCT_PROBLEM: "Houve um problema com o produto",
  INCORRECT_PRICE: "O preço da compra está incorreto",
  WRONG_AMOUNT: "O valor da compra está errado",
  PENDING_REFOUND: "Cancelei a compra com a loja mas não recebi o estorno",
  OVERCHARGE: "Fui cobrado mais de uma vez por essa compra",
  NO_RECOGNIZE_PURCHASE:
    "Não reconheço essa compra, suspeito de fraude em meu cartão",
} as const;

const initialState: State = {
  search: "",
  startDate: getStartDate(15), // Pega 15 dias para trás, a partir de hoje
  endDate: getEndDate(),
  reason: "",
  status: CONTESTATION_STATUS,
  dateErrors: {
    endDate: "",
    startDate: "",
  },
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_SEARCH":
      return { ...state, search: action.payload.search };
    case "SET_REASON":
      return { ...state, reason: action.payload.reason };
    case "SET_START_DATE":
      return handleStartDate(state, action.payload.startDate);
    case "SET_END_DATE":
      return handleEndDate(state, action.payload.endDate);
    case "SET_CONTESTATION_STATUS":
      return handleStatus(state, action.payload);
    case "CLEAR_FILTERS":
      return initialState;
    default:
      return state;
  }
}

const ContestationFilterContext = createContext<Context>({
  filterState: initialState,
  dispatchFilter: () => null,
});

export function ContestationFilterProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [filterState, dispatchFilter] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({ filterState, dispatchFilter }),
    [filterState, dispatchFilter],
  );

  return (
    <ContestationFilterContext.Provider value={value}>
      {children}
    </ContestationFilterContext.Provider>
  );
}

export function useContestationFilter() {
  const context = useContext(ContestationFilterContext);
  if (!context) {
    throw new Error(
      "ContestationFilterContext must be used within ContestationFilterProvider",
    );
  }
  return context;
}
