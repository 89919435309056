import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) =>
    theme.breakpoints.desktop(`
    gap: ${theme.spacings.inset.x3s};
    `)}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Container = styled(Box.withComponent("article"))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: ${({ theme }) =>
    `${theme.spacings.inset.x2s} ${theme.spacings.inset.md} ${theme.spacings.inset.x2s} ${theme.spacings.inset.x2s}`};

  background-color: ${({ theme }) => theme.palette.primary.lighter};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};

  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 108px;

  & > a,
  button {
    height: 100%;
    max-height: 96px;

    cursor: pointer;

    margin-right: ${({ theme }) => theme.spacings.inset.xs};

    outline: none;
    border: none;
    background: transparent;

    &:disabled {
      cursor: not-allowed;
    }
    & > img {
      height: 100%;
      max-height: 92px;
      width: 100%;
      max-width: 92px;
    }
  }

  ${({ theme }) =>
    theme.breakpoints.desktop(`
    padding: ${theme.spacings.inset.md} ${theme.spacings.inset.lg};

    border: unset;

    width: 100%;
    height: 132px;
    max-height: unset;
    background-color: ${theme.palette.neutral.white};

    & > a {
      height: 100px;
      & > img {
        height: 100%;
        max-height: 100px;
      }
    }

    & > button {
      outline: none;
      border: none;
      height: 100px;
      background: transparent;
      cursor: pointer;
      & > img {
        height: 100%;
        max-height: 100px;
      }
    }
    `)}
`;

export const Responsible = styled.h4`
  font-family: ${({ theme }) => theme.fonts.base};
  font-style: normal;
  color: ${({ theme }) => theme.palette.neutral.black};

  ${({ theme }) =>
    theme.breakpoints.mobile(`
    font-weight: ${theme.fontWeights.bold};
    font-size: ${theme.fontSizes.xs};
    line-height: ${theme.lineHeights.md};
    `)};

  ${({ theme }) =>
    theme.breakpoints.desktop(`
    display: none;
    `)}
`;

export const Description = styled.span`
  font-family: ${({ theme }) => theme.fonts.base};
  font-style: normal;
  line-height: ${({ theme }) => theme.lineHeights.md};

  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.palette.neutral.darkest};

  ${({ theme }) =>
    theme.breakpoints.mobile(`
    font-size: ${theme.fontSizes.x4s};
    `)};
`;

export const Key = styled.span`
  font-family: ${({ theme }) => theme.fonts.base};
  font-style: normal;
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.x2s};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
  }

  ${({ theme }) =>
    theme.breakpoints.desktop(`
    color: ${theme.palette.primary.main};
    `)}

  ${({ theme }) =>
    theme.breakpoints.mobile(`
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.lg};
    color: ${theme.palette.neutral.black};
    max-width: 165px;
  `)}
`;

export const IconButton = styled.button`
  &:disabled {
    cursor: not-allowed;
  }

  & > svg {
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    fill: ${({ theme }) => theme.palette.neutral.darkest};

    ${({ theme }) =>
      theme.breakpoints.mobile(`
      fill: ${theme.palette.primary.main};
    `)}

    &:hover {
      cursor: pointer;
    }
    &:hover:not(disabled) {
      cursor: unset;
      fill: unset;
    }
  }
`;
