import styled from "styled-components";

export type IBoxProps = {
  shadow?: "full" | "lower";
};

export const Box = styled.div<IBoxProps>`
  background: ${({ theme }) => theme.palette.neutral.white};
  border: 1px solid ${({ theme }) => theme.palette.neutral.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  box-shadow: ${({ theme, shadow }) => {
    const shadowMap = {
      full: theme.shadows.full,
      lower: theme.shadows.lower,
    };
    return shadowMap[shadow];
  }};
`;
