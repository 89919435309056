import { IconProps } from "@hyperlocal/vital-icons";

export const keysTypes: Array<{
  title: string;
  type: string;
  mask?: string;
  icon: IconProps["name"];
}> = [
  {
    title: "E-mail",
    type: "Email",
    icon: "GeralMail",
  },
  {
    title: "Celular",
    type: "PhoneNumber",
    icon: "GeralPhone",
    mask: "(99) 99999-9999",
  },
  {
    title: "CNPJ",
    type: "CNPJ",
    icon: "GeralStore",
    mask: "99.999.999/9999-99",
  },
  {
    title: "CPF",
    type: "NationalRegistration",
    icon: "GeralUserPerson",
    mask: "999.999.999-99",
  },
  {
    title: "Chave Aleatória",
    type: "EVP",
    icon: "GeralKey",
  },
  {
    title: "Código Pix",
    type: "EVP",
    icon: "MoneyPix",
  },
];
