import { ReactNode, useState } from "react";
import { useHandleCancelPixContestation } from "@/pages/contestation/api/use-pix-list";
import { SheetDetails } from "@/pages/contestation/components/pix-per-period/components/card-button";
import { REASON_MAP } from "@/pages/contestation/contexts/contestation-filter";
import * as Svg from "@/pages/contestation/svgs";
import { getStatusFormatted } from "@/pages/contestation/utils/get-status-formatted";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency, Hooks } from "@hyperlocal/banking-utility";
import { Modal } from "@hyperlocal/vital";
import {
  Button,
  Drawer,
  Sheet,
  Token,
  type SheetRootProps,
} from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";

type Steps = "details" | "token";

interface DetailsSheetProps {
  details: SheetDetails;
  goToNextStep: () => void;
}

export function DetailsSheet({ details, goToNextStep }: DetailsSheetProps) {
  const isMobile = Hooks.useMediaQuery("mobile");
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleToggleCancelContestationModal() {
    setIsModalOpen((oldState) => !oldState);
  }

  return (
    <div className="flex flex-1 flex-col justify-between gap-6 desktop:max-w-[432px]">
      <div className="flex flex-col gap-4 mobile:gap-3">
        <header className="mx-auto mt-8 flex flex-col items-center mobile:mb-2">
          <Svg.Pix
            className={twMerge(
              "box-content rounded-circle p-5",
              details.status === "CANCELED" &&
                "bg-status-error-light text-status-error-dark",
              details.status === "PENDING" &&
                "bg-status-warning-light text-status-warning-dark",
            )}
          />
          <h4 className="mb-1 mt-4 text-sm/md font-bold text-neutral-darkest mobile:mb-2 mobile:mt-6">
            Contestação Pix
          </h4>
          <span
            className={twMerge(
              "text-sm/md font-bold capitalize text-primary-darker",
              details.status === "CANCELED" && "text-status-error-dark",
              details.status === "PENDING" && "text-status-warning-dark",
            )}
          >
            {getStatusFormatted(details.status)}
          </span>
        </header>

        <div className="h-px w-full bg-neutral-light" />

        <div className="flex items-center justify-between">
          <span className="text-xs/default font-medium text-neutral-darkest mobile:text-x2s">
            Valor
          </span>
          <span
            className={twMerge(
              "text-sm/md font-bold text-neutral-darker mobile:text-xs",
              details.status === "CANCELED" && "text-status-error-dark",
              details.status === "PENDING" && "text-status-warning-dark",
            )}
          >
            {formatCurrency(details.amount)}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-xs/default font-medium text-neutral-darkest mobile:text-x2s">
            Data da transferência
          </span>
          <span className="text-xs/md font-regular text-neutral-dark mobile:text-x2s">
            {new Date(details.transferDate).toLocaleDateString()}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-xs/default font-medium text-neutral-darkest mobile:text-x2s">
            Data do pedido
          </span>
          <span className="text-xs/md font-regular text-neutral-dark mobile:text-x2s">
            {new Date(details.requestDate).toLocaleDateString()}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-xs/default font-medium text-neutral-darkest mobile:text-x2s">
            Transferido para
          </span>
          <span className="text-xs/md font-regular capitalize text-neutral-dark mobile:text-x2s">
            {details.person}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-xs/default font-medium text-neutral-darkest mobile:text-x2s">
            Instituição
          </span>
          <span className="text-xs/md font-regular text-neutral-dark mobile:text-x2s">
            {details.institution}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-xs/default font-medium text-neutral-darkest mobile:text-x2s">
            Chave Pix
          </span>
          <span className="text-xs/md font-regular text-neutral-dark mobile:text-x2s">
            {details.pixKey}
          </span>
        </div>

        <div className="h-px w-full bg-neutral-light" />

        <div>
          <span className="mb-2 block text-xs/default font-medium text-neutral-darkest mobile:text-x2s">
            Motivo da contestação
          </span>
          <span className="block text-xs/md font-regular text-neutral-dark mobile:text-x2s">
            {REASON_MAP[details.reason]}
          </span>
        </div>

        <div className="h-px w-full bg-neutral-light" />

        <div>
          <span className="mb-2 block text-xs/default font-medium text-neutral-darkest mobile:text-x2s">
            Id da Operação
          </span>
          <span className="block text-xs/md font-regular text-neutral-dark mobile:text-x2s">
            {details.id}
          </span>
        </div>
      </div>

      {details.status === "PENDING" && (
        <>
          <Button.Root
            onClick={handleToggleCancelContestationModal}
            className="text-status-error-dark hover:text-status-error-dark focus:text-status-error-dark"
            variant="link"
          >
            Cancelar contestação
          </Button.Root>

          {isMobile ? (
            <Drawer.Root
              open={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            >
              <Drawer.Content>
                <Drawer.Header>
                  <Drawer.Title>Cancelar contestação</Drawer.Title>
                </Drawer.Header>

                <span className="my-6 block text-xs/md text-neutral-darker">
                  Tem certeza que quer desistir da contestação? Isso encerrará o
                  processo de avaliação em andamento.
                </span>

                <div className="mb-4 flex gap-2">
                  <Button.Root
                    className="w-full"
                    variant="secondary"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Voltar
                  </Button.Root>
                  <Button.Root
                    className="w-full border-0 !bg-status-error-default"
                    onClick={goToNextStep}
                  >
                    Desistir
                  </Button.Root>
                </div>
              </Drawer.Content>
            </Drawer.Root>
          ) : (
            <Modal
              isOpen={isModalOpen}
              title="Cancelar contestação"
              textContent="Tem certeza que quer desistir da contestação? Isso encerrará o processo de avaliação em andamento."
              variant="button"
              onClose={handleToggleCancelContestationModal}
              footerProps={{
                variant: "button",
                buttonsProps: [
                  {
                    children: "Voltar",
                    variant: "secondary",
                    onClick: handleToggleCancelContestationModal,
                  },
                  {
                    children: "Desistir",
                    variant: "primary",
                    style: { background: "#EA4E2C" },
                    onClick: goToNextStep,
                  },
                ],
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

interface TokenSheetProps {
  id: string;
  goToInitialStep: () => void;
}

export function TokenSheet({ id, goToInitialStep }: TokenSheetProps) {
  const [totpCode, setTotpCode] = useState("");
  const mutation = useHandleCancelPixContestation();

  async function handleVerification() {
    // TODO: Verificação
    await new Promise((resolve) => setTimeout(resolve, 500));
    mutation.mutate({ id });
    goToInitialStep();
  }

  const isDisabled = totpCode.length !== 6;

  return (
    <div className="flex flex-1 flex-col">
      <h2 className="my-4 text-sm/md font-bold text-neutral-darkest mobile:text-xs">
        Informe o código de autenticação
      </h2>

      <h3 className="mb-5 text-x2s/normal text-primary-main">
        {/* TODO: Pegar e-mail do usuário */}
        vitor.nishida@hyperlocal.com.br
      </h3>

      <ul className="mx-5 list-disc text-neutral-darkest mobile:text-x2s/normal">
        <li>
          Abra seu aplicativo Google Authenticator e insira o código de seis
          dígitos exibido no aplicativo.
        </li>
      </ul>

      <div className="mt-8 w-fit">
        <Token.Label className="mobile:text-x2s/default">
          Digite o código
        </Token.Label>
        <Token.Root onValueChange={setTotpCode}>
          <Token.Input />
          <Token.Input />
          <Token.Input />
          <Token.Input />
          <Token.Input />
          <Token.Input />
        </Token.Root>
      </div>

      <Button.Root
        variant="primary"
        className="mt-auto w-full justify-center"
        onClick={handleVerification}
        disabled={isDisabled}
      >
        Verificar
      </Button.Root>
    </div>
  );
}

type ContestationDetailsSheetProps = SheetRootProps & {
  details: SheetDetails;
};

export function ContestationDetailsSheet({
  details,
  children,
  ...props
}: ContestationDetailsSheetProps) {
  const [step, setStep] = useState<Steps>("details");

  function goToNextStep() {
    setStep("token");
  }

  function goToInitialStep() {
    setStep("details");
  }

  function onOpenChange(open: boolean) {
    if (open) {
      goToInitialStep();
    }
  }

  const steps: Record<Steps, { title: string; component: ReactNode }> = {
    details: {
      title: "Detalhe da contestação",
      component: <DetailsSheet details={details} goToNextStep={goToNextStep} />,
    },
    token: {
      title: "Validação",
      component: (
        <TokenSheet id={details.id} goToInitialStep={goToInitialStep} />
      ),
    },
  };

  return (
    <Sheet.Root {...props} onOpenChange={onOpenChange}>
      <Sheet.Trigger asChild>{children}</Sheet.Trigger>

      <Sheet.Content className="mobile:p-4">
        <Sheet.Header>
          <Sheet.Title className="text-2xl/md font-bold text-neutral-darkest mobile:text-sm/default">
            {steps[step].title}
          </Sheet.Title>
        </Sheet.Header>

        {steps[step].component}
      </Sheet.Content>
    </Sheet.Root>
  );
}
