import { ReactNode, useEffect, useState } from "react";
import { usePixKeysContext } from "@/context/Pix";
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  API,
  getAllRoutes,
  getDeletKey,
  getTokenData,
  getUser,
  Hooks,
  ProtectedComponent,
  setHeader,
  setDeleteKey as setRequest,
  setTokenData,
  useAccountStore,
  Validator,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import {
  Button,
  Card,
  Chips,
  ConfirmationCircle,
  Divider,
  Drawer,
  Skeleton,
} from "@hyperlocal/vital";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { useDrawer } from "../../context/Drawer";
import { useUrlDisclosure } from "../../hooks";
import { apis as pixApi } from "../../services";
// @ts-ignore
import SharedDrawer from "../ShareKey";
import ContentQR from "../ShareModalQR";
import {
  DeleteKeyContent,
  ReceivedClaimContent,
  RequestedClaimContent,
} from "./DrawersAndModals";
import { FieldInfo } from "./FieldInfo";
import { KeyCard } from "./KeyCard";
import * as S from "./styles";
import * as Svg from "./svgs";
import * as ITypes from "./types";

enum PAGES {
  "MY_KEYS",
  "KEY",
  "SUCESS_SCREEN",
}

const { checkPixKey } = Validator;

export type MyKeysResponse = {
  key: string;
  keyType: "Email" | "PhoneNumber" | "NationalRegistration" | "EVP";
  createdAt: string;
  description: string;
  keyStatus: "OPEN" | "WAITING_RESOLUTION";
  account: {
    ispb: number;
    document: string;
    name: string;
    bankAccountNumber: string;
    bankBranchNumber: string;
    bankAccountType: string;
  };
  claim?: {
    claimType: string;
    playerType: string;
    claimStatus: string;
    dateKeyClaimed: string;
    grantorDeadline: string;
    claimDeadline: string;
    claimUUID: string;
    claimantAccount: {
      claimName: string;
      bankAccountNumber: string;
      bankBranchNumber: string;
      bankAccountType: string;
    };
  };
};

type MyClaimsResponse = {
  previousPage: number;
  currentPage: number;
  nextPage: number;
  last: boolean;
  totalPages: number;
  totalItems: number;
  totalItemsPage: number;
  items: {
    playerType: string;
    ispb: string;
    claim: {
      key: string;
      keyType: string;
      claimStatus: string;
      dateKeyClaimed: string;
      lastModified: string;
      grantorDeadline: string;
      claimDeadline: string;
      claimUUID: string;
    };
  }[];
};

const MyKeys: React.FC<ITypes.IMyKeys> = ({ handleModal }) => {
  const { dispatchFilter } = usePixKeysContext();

  const isMobile = Hooks.useMediaQuery("mobile");
  const navigate = useNavigate();
  const { pixRoutes: routes } = getAllRoutes();
  const token = getTokenData();
  const [page, setPage] = useState<PAGES>(PAGES.MY_KEYS);
  const [selectedKey, setSelectedKey] = useState<
    MyKeysResponse & { keyQrCode?: string }
  >(null);
  // const [claims, setClaims] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const { drawerContent: drawer, handleDrawerContent: setDrawer } = useDrawer();
  const [searchParams] = useSearchParams();
  const newKeyDrawer = useUrlDisclosure("newKey");
  const { account } = useAccountStore();
  const {
    data: keys = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["pixKeys"],
    queryFn: async () => {
      try {
        const response = await API.pix.get<MyKeysResponse[]>(
          "/api/v1/pix/Key/allKeys",
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    refetchOnWindowFocus: true,
  });
  const { data: claimsRequested } = useQuery({
    queryKey: ["pixClaims"],
    queryFn: async () => {
      try {
        const response = await API.pix.get<MyClaimsResponse>(
          "/api/v1/pix/Claims/find",
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    refetchOnWindowFocus: true,
  });
  const [modal, setModal] = useState<{ open: boolean; content?: ReactNode }>({
    open: false,
  });

  const request = getDeletKey();
  const user = getUser();

  const closeDrawer = () => {
    setDrawer({ ...drawer, open: false });
  };
  const closeModal = () => setModal({ ...modal, open: false });

  const openImageQrModal = () => {
    closeDrawer();

    setModal({
      open: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      content: <ContentQR defaultKey={selectedKey} closeModal={closeModal} />,
    });
  };

  const copyDefaultKey = () => navigator.clipboard.writeText(selectedKey.key);

  const openSharedDrawer = () => {
    setDrawer({
      title: "Compartilhar QR Code",
      open: true,
      content: (
        <SharedDrawer
          openImageQrModal={openImageQrModal}
          copyDefaultKey={copyDefaultKey}
        />
      ),
    });
  };

  const goToKey = async (selectedKey: MyKeysResponse) => {
    const response = await API.pix.post("/api/v1/pix/QrCode/static", {
      key: selectedKey.key,
    });
    const imageQrCodeBase64 = response.data.image;

    setSelectedKey({ ...selectedKey, keyQrCode: imageQrCodeBase64 });
    setPage(PAGES.KEY);
  };

  // const changeHeader = async (data: UTypes["IHeader"]) => {
  //   setHeader(data);
  // };

  // const backToDashboardPix = () => {
  //   navigate(routes.pix);
  // };

  const goToToken = () => {
    if (isMobile) {
      navigate(routes.token);
    } else {
      handleModal({ isOpen: false });

      setDrawer({
        title: "Token",
        open: true,
        content: (
          // @ts-ignore
          <Parcel
            // @ts-ignore
            config={() => System.import("@hyperlocal/banking-token-validation")}
            mountParcel={mountRootParcel}
          />
        ),
      });
    }
  };

  const backToMyKeys = () => {
    navigate(routes.pixMyKeys);
  };

  // const goToMyKeys = () => {
  //   setPage(PAGES.MY_KEYS);
  // };

  // const headerGoToKeys = () => {
  //   goToMyKeys();
  //   setRequest(undefined);
  //   setTokenData(undefined);
  // };

  const goToNewKey = () => {
    dispatchFilter({ type: "SET_NEW_PIX_KEY", payload: { newPixKey: "" } });

    if (isMobile) {
      return navigate(routes.pixMyKeysNew);
    }
    newKeyDrawer.open();
  };

  const handlePixToken = () => {
    if (searchParams.has("newKey")) return;
    const keyRequest = request.key;
    // @ts-ignore
    setSelectedKey(keyRequest);

    request.action === "cancelClaim"
      ? cancelClaim(keyRequest)
      : deleteKey(keyRequest);
  };

  // const getAllClaims = () => {
  //   const accountId = user?.profiles?.Customers?.[0]?.guid_account;
  //   const userId = user?.login?.guid_user;

  //   Promise.allSettled([
  //     pixApi.listAllClaimsRequested(accountId, userId),
  //     pixApi.listAllClaimsReceived(accountId, userId),
  //   ])
  //     .then((results) => {
  //       const activeClaims = [];
  //       results.forEach((result) => {
  //         if (result.status === "fulfilled") {
  //           const { data } = result.value;

  //           if (data) {
  //             const filteredClaims = data.filter(
  //               (claim) => claim.status === "PENDING",
  //             );
  //             activeClaims.push(...filteredClaims);
  //           }
  //         } else {
  //           console.error("Error in fetching claims:", result.reason);
  //         }
  //       });

  //       setClaims(activeClaims);
  //     })
  //     .catch((error) => {
  //       console.error("Error in getAllClaims:", error);

  //       setClaims([]);
  //     });
  // };

  // const responseClaim = async (key, response: boolean, claimType: string) => {
  //   try {
  //     const reason =
  //       !response && claimType === "Reivindicação" ? "FRAUD" : "USER_REQUEST";

  //     await pixApi.responseMigration(
  //       {
  //         accept: response,
  //         claimId: key.claimId,
  //         reason,
  //       },
  //       user?.profiles?.Customers?.[0]?.guid_account,
  //       user?.login?.guid_user,
  //     );

  //     setRequest(undefined);
  //     setPage(PAGES.MY_KEYS);
  //     refetch();
  //     setDrawer(undefined);
  //   } catch (error) {
  //     const err: string = error?.response?.data?.errors
  //       ?.map((error) => error.friendlyMessage)
  //       ?.join(" ");

  //     console.error("Erro ao responder portabilidade/reivindicação: ", err);
  //     openDynamicDrawer("Erro ao responder portabilidade/reivindicação", err);
  //   }
  // };

  const cancelClaim = async (key) => {
    try {
      await pixApi.cancelMigration(
        {
          tokenCode: `${token.response.token}`,
          claimId: key.claimId,
          reason: "USER_REQUEST",
        },
        user?.profiles?.Customers?.[0]?.guid_account,
        user?.login?.guid_user,
      );

      setTokenData(undefined);
      setRequest(undefined);

      refetch();

      setPage(PAGES.MY_KEYS);
    } catch (error) {
      const err: string = error?.response?.data?.errors
        ?.map((error) => error.friendlyMessage)
        ?.join(" ");

      console.error("Erro ao cancelar portabilidade: ", error);
      openDynamicDrawer("Erro ao cancelar portabilidade", err);
    } finally {
      setFetching(false);
    }
  };

  const deleteKey = async (key) => {
    setFetching(true);

    setPage(PAGES.KEY);

    try {
      await pixApi.delete(
        {
          key: key?.key,
          tokenCode: token.response.token,
        },
        user?.profiles?.Customers?.[0]?.guid_account,
        user?.login?.guid_user,
      );

      setTokenData(undefined);
      setPage(PAGES.SUCESS_SCREEN);
      setRequest(undefined);

      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  const createTokenRequest = () => {
    const path = isMobile ? routes.pixMyKeys : `${routes.pix}?myKeys=open`;
    setTokenData({
      request: {
        guid_token_type: "948EB37E-5EFC-42C4-ADD5-256675D11FCC",
        guid_user: user?.login?.guid_user,
        path,
        header: {
          title: "Exclusão de chave",
          leftIcon: {
            name: "ArrowArrowNoLineLeft",
            onClick: backToMyKeys,
          },
        },
      },
    });
    goToToken();
  };

  const handleDeletePixKey = (key: string) => {
    return async () => {
      await API.pix.delete("/api/v1/pix/Key", {
        data: { key },
      });
      setPage(PAGES.SUCESS_SCREEN);
      handleModal({ isOpen: false });
    };
  };

  const openDrawerDeleteKey = () => {
    if (isMobile) {
      setDrawer({
        title: "Excluir chave Pix",
        open: true,
        content: (
          <DeleteKeyContent
            checkPixKey={checkPixKey}
            createTokenRequest={handleDeletePixKey(selectedKey?.key)}
            selectedKey={selectedKey?.key}
          />
        ),
      });
    } else {
      handleModal({
        title: "Excluir chave Pix",
        variant: "custom",
        isOpen: true,
        textContent: "",
        children: (
          <DeleteKeyContent
            checkPixKey={checkPixKey}
            createTokenRequest={handleDeletePixKey(selectedKey?.key)}
            selectedKey={selectedKey?.key}
          />
        ),
      });
    }
  };

  // const changeDefaultKey = async (e: boolean) => {
  //   try {
  //     await pixApi.changeDefaultKey(
  //       {
  //         default: e,
  //         key: selectedKey.key,
  //       },
  //       user?.profiles?.Customers?.[0]?.guid_account,
  //       user?.login?.guid_user,
  //     );
  //     refetch();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getKeys = useCallback(async () => {
  //   const response = await pixApi.listKeys(
  //     user?.profiles?.Customers?.[0]?.guid_account,
  //     user?.login?.guid_user,
  //   );
  //   return response.data;
  // }, [user?.login?.guid_user, user?.profiles?.Customers]);

  const getIconByKeyType = (keyType: string): IconProps["name"] => {
    switch (keyType) {
      case "EVP":
        return "GeralKey";
      case "CPF":
        return "GeralUserPerson";
      case "CNPJ":
        return "GeralStore";
      case "PHONE":
        return "GeralPhone";
      case "EMAIL":
        return "GeralMail";
      default:
        return "TagsAlertSlash";
    }
  };

  const handleRequestedClaim = async (action: string, key) => {
    setRequest({ ...request, action, key });

    if (action === "acceptClaim") {
      await API.pix.post("/api/v1/pix/Claims/approve", {
        claimUUID: key.claim.claimUUID,
      });
    } else if (action === "declineClaim" || action === "cancelClaim") {
      await API.pix.post("/api/v1/pix/Claims/refused", {
        claimUUID: key.claim.claimUUID,
      });
    } else {
      createTokenRequest();
    }
  };

  const getPortabilityMaxDate = (createdAd: Date) => {
    createdAd.setDate(createdAd.getDate() + 7);

    return createdAd.toLocaleDateString();
  };

  const openReceivedClaimDrawer = async (key: MyKeysResponse) => {
    const actualClaim = claims.filter((claim) => claim.key === key.key)[0];

    if (!actualClaim?.claim.claimUUID) {
      openDynamicDrawer(
        "Aguarde um instante",
        "Estamos buscando esta informação, tente novamente em alguns minutos",
      );

      return;
    }

    const claimType =
      actualClaim.claim.claimType === "KEY_TRANSFER"
        ? "Reivindicação"
        : "Portabilidade";

    setDrawer({
      title: `${claimType} de chave Pix`,
      open: true,
      content: (
        <ReceivedClaimContent
          keyInfo={key}
          claimType={claimType}
          getIconByKeyType={getIconByKeyType}
          getPortabilityMaxDate={getPortabilityMaxDate}
          handleRequestedClaim={handleRequestedClaim}
        />
      ),
    });
  };

  const openDynamicDrawer = (title: string, message: string) => {
    setDrawer({
      title,
      open: true,
      content: (
        <S.WapperDrawerDelete>
          <p>{message}</p>
        </S.WapperDrawerDelete>
      ),
    });
  };

  const openRequestedClaimDrawer = async (
    key: MyClaimsResponse["items"][number],
  ) => {
    if (!key?.claim.claimUUID) {
      openDynamicDrawer(
        "Aguarde um instante",
        "Estamos buscando esta informação, tente novamente em alguns minutos",
      );

      return;
    }

    setDrawer({
      title: "Portabilidade de chave Pix",
      open: true,
      content: (
        <RequestedClaimContent
          claimType={"Portabilidade"}
          getIconByKeyType={getIconByKeyType}
          handleRequestedClaim={handleRequestedClaim}
          pixKey={key}
        />
      ),
    });
  };

  // useEffect(() => {
  //   if (page === PAGES.MY_KEYS) {
  //     changeHeader({
  //       title: "Minhas Chaves Pix",
  //       show: isMobile,
  //       leftIcon: {
  //         name: "ArrowArrowNoLineLeft",
  //         onClick: backToDashboardPix,
  //       },
  //     });
  //   } else if (page === PAGES.SUCESS_SCREEN) {
  //     changeHeader({
  //       title: "Comprovante",
  //       show: isMobile,
  //       leftIcon: {
  //         name: "ArrowArrowNoLineLeft",
  //         onClick: goToMyKeys,
  //       },
  //     });
  //   } else {
  //     changeHeader({
  //       title: "Minhas Chaves Pix",
  //       show: isMobile,
  //       leftIcon: {
  //         name: "ArrowArrowNoLineLeft",
  //         onClick: headerGoToKeys,
  //       },
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page]);

  useEffect(() => {
    if (token?.response?.token) {
      handlePixToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    // @ts-ignore
    setRequest({ ...request, key: selectedKey });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey]);

  // useEffect(() => {
  //   if (user) {
  //     getAllClaims();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  useEffect(() => {
    if (
      token?.request &&
      [routes.pix, routes.pixMyKeys].includes(token?.request.path) &&
      request?.key?.key
    ) {
      // @ts-ignore
      setSelectedKey(request.key);
      setPage(PAGES.KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backToMyKeysSucess = () => {
    navigate(routes.pix);
  };

  useEffect(() => {
    if (!isMobile) return;

    if (window.location.pathname.includes("minhas-chaves"))
      setHeader({
        title: `Minhas chaves`,
        leftIcon: {
          name: "ArrowArrowNoLineLeft",
          onClick: backToMyKeysSucess,
        },
      });
  }, [backToMyKeysSucess, isMobile]);

  const { document } =
    account.companyInfoResponse || account.personInfoResponse;

  const claims = keys.filter((key) => key.keyStatus === "WAITING_RESOLUTION");
  const documentType = checkPixKey(document);
  const keysLimit = documentType === "CNPJ" ? 20 : 4;

  if (page === PAGES.MY_KEYS) {
    return (
      <>
        <S.Main>
          <div>
            {isMobile && <h2>Minhas Chaves</h2>}
            <p>
              Com o Pix você tem uma nova forma de fazer transações, tudo que
              precisa para pagar, transferir ou cobrar.
            </p>
            {isLoading ? (
              <nav>
                <Card>
                  <div>
                    <Skeleton width="48px" height="48px" borderRadius="100%" />
                    <p>
                      <span>
                        <Skeleton width="210px" />
                      </span>
                      <span>
                        <Skeleton width="210px" />
                      </span>
                    </p>
                  </div>
                </Card>
              </nav>
            ) : (
              keys.map((key) => {
                if (key.keyStatus !== "OPEN") return null;

                return <KeyCard goToKey={goToKey} pixKey={key} key={key.key} />;
              })
            )}

            <h2>Reivindicações / Portabilidade</h2>
            {claims.length > 0 || claimsRequested?.items.length > 0 ? (
              <>
                {claims.map((claim) => {
                  return (
                    <nav key={claim?.key} className="claim">
                      <Card onClick={() => openReceivedClaimDrawer(claim)}>
                        <div>
                          <Icon
                            className="keyTypeIcon"
                            name={getIconByKeyType(claim?.keyType)}
                          />
                          <p>
                            <span>
                              {claim?.keyType === "PhoneNumber"
                                ? checkPixKey(claim?.key?.replace("+55", ""))
                                : checkPixKey(claim?.key)}
                            </span>
                            <span>
                              {" "}
                              {claim?.key.length > 23
                                ? `${claim?.key.substring(0, 20)}...`
                                : claim?.key}
                            </span>
                          </p>
                        </div>
                        <Chips variant="tag" color="warning">
                          Pendente
                        </Chips>
                      </Card>
                    </nav>
                  );
                })}
                {claimsRequested?.items.map((claim) => {
                  return (
                    <nav key={claim?.claim.key} className="claim">
                      <Card onClick={() => openRequestedClaimDrawer(claim)}>
                        <div>
                          <Icon
                            className="keyTypeIcon"
                            name={getIconByKeyType(claim?.claim.keyType)}
                          />
                          <p>
                            <span>
                              {claim?.claim.keyType === "PhoneNumber"
                                ? checkPixKey(
                                    claim?.claim.key?.replace("+55", ""),
                                  )
                                : checkPixKey(claim?.claim.key)}
                            </span>
                            <span>
                              {" "}
                              {claim?.claim.key.length > 23
                                ? `${claim?.claim.key.substring(0, 20)}...`
                                : claim?.claim.key}
                            </span>
                          </p>
                        </div>
                        <Chips variant="tag" color="warning">
                          Pendente
                        </Chips>
                      </Card>
                    </nav>
                  );
                })}
              </>
            ) : (
              <p className="noClaim">
                Não foram encontradas solicitações de
                Reivindicação/Portabilidade para esta conta.
              </p>
            )}
          </div>
          {keys.length < keysLimit && (
            <div className="WrapperButton">
              <Button onClick={goToNewKey}>
                <Svg.Pix />
                Cadastrar chave Pix
              </Button>
            </div>
          )}
        </S.Main>
        <Drawer
          open={drawer?.open ?? false}
          title={drawer?.title}
          position={isMobile ? "bottom" : "right"}
          onDismiss={closeDrawer}
        >
          {drawer?.content}
        </Drawer>
      </>
    );
  }

  if (page === PAGES.SUCESS_SCREEN) {
    return (
      <S.SucessScreen>
        <div>
          <ConfirmationCircle />

          <div className="flex flex-col items-center gap-inset-x3s">
            <h2 className="text-sm font-bold leading-8 text-neutral-darkest">
              Chave Pix
            </h2>
            <h2 className="text-sm font-bold leading-8 text-primary-main">
              Excluída
            </h2>
          </div>

          <Divider />

          <div>
            <h3>Requisição de Exclusão</h3>
            <p>O processo de exclusão de Chave Pix foi efetuado com sucesso.</p>
          </div>
        </div>

        <div className="wrapperButtons">
          <Button
            variant="secondary"
            onClick={goToNewKey}
            disabled={
              user?.login?.PF_PJ === "PF" && !!(keys.length < 5)
                ? false
                : !(user?.login?.PF_PJ === "PJ" && !!(keys.length < 20))
            }
          >
            Cadastrar outra chave
          </Button>
        </div>
      </S.SucessScreen>
    );
  }

  return (
    <S.WrapperKey>
      <div>
        <section>
          {modal.open ? modal?.content : null}

          <Drawer
            open={drawer?.open ?? false}
            title={drawer?.title}
            position={isMobile ? "bottom" : "right"}
            onDismiss={closeDrawer}
          >
            {drawer?.content}
          </Drawer>
        </section>

        <div>
          <h2 className="mt-inset-xl text-xs font-bold leading-7">
            Detalhes da chave
          </h2>
        </div>

        <div className="flex flex-col !gap-0">
          <p className="text-x2s leading-6 text-neutral-darker">Chave Pix</p>
          <p className="text-xs leading-7 !text-neutral-dark">
            {selectedKey?.key}
          </p>
        </div>

        <div className="flex flex-col !gap-inset-md">
          <FieldInfo label="Instituição" value="301 - Hyperlocal Bank. SA" />
          <FieldInfo label="Tipo da conta" value="Conta Corrente" />
          <FieldInfo
            label="Agência"
            value={selectedKey.account.bankBranchNumber}
          />
          <FieldInfo
            label="Conta"
            value={selectedKey.account.bankAccountNumber}
          />
          <FieldInfo
            statusLabel="active"
            label="Status da chave"
            value={selectedKey?.keyStatus === "OPEN" ? "Ativo" : "Pendente"}
          />
        </div>
      </div>

      <div className="flex w-full flex-col items-center">
        <Button
          className="flex w-full items-center justify-center text-center"
          isLoading={isFetching}
          onClick={openSharedDrawer}
          icon="ComputersShare"
        >
          Compartilhar
        </Button>

        <ProtectedComponent
          allowedRoles={["Proprietário", "Administrador", "Moderador"]}
        >
          <Button
            className="flex w-full items-center justify-center text-center !text-status-error-dark"
            variant="link"
            isLoading={isFetching}
            onClick={openDrawerDeleteKey}
          >
            <Icon
              className="!fill-status-error-dark"
              name="SettingTrashTrash"
            />{" "}
            Excluir chave
          </Button>
        </ProtectedComponent>
      </div>
    </S.WrapperKey>
  );
};

export default MyKeys;
