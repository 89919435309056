import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > button {
    padding: 0 !important;
  }
`;

export const Message = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.x2s};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.dark};
  margin-bottom: ${({ theme }) => theme.spacings.inset.md};
  margin-top: ${({ theme }) => theme.spacings.inset.lg};
`;

export const MessageTiming = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.palette.neutral.main};
  margin-bottom: ${({ theme }) => theme.spacings.inset.md};
  text-decoration: underline;
`;
