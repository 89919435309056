import { ComponentProps } from "react";

export function SearchLupe(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.025 20.05L13.25 14.3c-.5.417-1.075.742-1.725.975-.65.233-1.317.35-2 .35-1.717 0-3.167-.592-4.35-1.775C3.992 12.667 3.4 11.217 3.4 9.5c0-1.7.592-3.146 1.775-4.338 1.183-1.191 2.633-1.787 4.35-1.787 1.7 0 3.142.592 4.325 1.775 1.183 1.183 1.775 2.633 1.775 4.35 0 .717-.117 1.4-.35 2.05a5.612 5.612 0 01-.95 1.7l5.775 5.775c.133.133.2.3.2.5a.72.72 0 01-.225.525.733.733 0 01-.537.225.655.655 0 01-.513-.225zm-9.5-5.925c1.283 0 2.37-.45 3.263-1.35.891-.9 1.337-1.992 1.337-3.275s-.446-2.375-1.337-3.275c-.892-.9-1.98-1.35-3.263-1.35-1.3 0-2.396.45-3.287 1.35C5.346 7.125 4.9 8.217 4.9 9.5s.446 2.375 1.338 3.275c.891.9 1.987 1.35 3.287 1.35z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowDown(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.475 17.125l-4.5-4.5a.822.822 0 01-.188-.288.935.935 0 010-.675.825.825 0 01.188-.287l4.5-4.5A.72.72 0 0114 6.65c.2 0 .375.075.525.225a.72.72 0 01.225.525.72.72 0 01-.225.525L10.45 12l4.075 4.075a.72.72 0 01.225.525.72.72 0 01-.225.525.72.72 0 01-.525.225.72.72 0 01-.525-.225z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Close(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 13.05l-5.075 5.075a.736.736 0 01-.512.212.69.69 0 01-.538-.212.72.72 0 01-.225-.525c0-.2.075-.375.225-.525L10.95 12 5.875 6.925a.737.737 0 01-.212-.513.69.69 0 01.212-.537.72.72 0 01.525-.225c.2 0 .375.075.525.225L12 10.95l5.075-5.075a.74.74 0 01.513-.213.693.693 0 01.537.213.72.72 0 01.225.525.72.72 0 01-.225.525L13.05 12l5.075 5.075a.735.735 0 01.212.512.69.69 0 01-.212.538.72.72 0 01-.525.225.72.72 0 01-.525-.225L12 13.05z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Pix(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.956 11.734a2.843 2.843 0 00-.841-1.836c-.73-.741-1.471-1.471-2.212-2.212a.365.365 0 00-.244-.089h-1.161c-.675-.022-1.228.221-1.693.697l-2.798 2.799c-.72.719-1.549.719-2.268 0-.951-.94-1.903-1.892-2.843-2.843-.343-.343-.741-.587-1.228-.62-.453-.033-.896-.022-1.35-.022-.088 0-.199.055-.265.11-.719.709-1.416 1.417-2.124 2.125a2.993 2.993 0 00-.863 1.637L2 11.856v.32s.033.1.044.156c.067.696.354 1.305.841 1.803.73.74 1.471 1.47 2.212 2.212a.329.329 0 00.222.089h.94c.664.022 1.228-.222 1.692-.697.93-.952 1.87-1.88 2.821-2.821.144-.133.299-.266.465-.354.586-.299 1.239-.177 1.736.32.963.963 1.925 1.914 2.877 2.877.453.453.984.686 1.626.675h1.194a.479.479 0 00.288-.122c.708-.697 1.405-1.405 2.113-2.113.376-.365.663-.796.796-1.316.067-.255.1-.52.155-.786v-.188s-.033-.1-.044-.155l-.022-.022zm-12.213.42c-.885.886-1.77 1.76-2.632 2.644-.255.255-.542.366-.907.388-.377.022-.642-.1-.896-.377-.465-.508-.974-.984-1.46-1.47-.808-.808-.808-1.87 0-2.689.564-.564 1.128-1.128 1.692-1.703.1-.089.199-.122.32-.133.609-.078 1.063.155 1.472.597.774.83 1.593 1.604 2.4 2.412.056.055.122.066.21.11-.121.122-.154.177-.21.222h.011zm10.432 1.151c-.575.586-1.15 1.162-1.737 1.737a.409.409 0 01-.299.122c-.254.022-.508 0-.763 0a.838.838 0 01-.586-.233c-.963-.962-1.936-1.925-2.898-2.887 0-.022 0-.022-.022-.033l-.056-.078c.056-.033.133-.055.188-.11.896-.896 1.792-1.781 2.677-2.688.221-.222.476-.354.797-.321.276 0 .575-.066.807.022.244.1.42.365.609.564.42.42.851.84 1.272 1.272.763.786.774 1.848 0 2.622l.01.011z"
        fill="currentColor"
      />
      <path
        d="M17.763 6.535c-1.206-1.194-2.411-2.411-3.617-3.617a2.832 2.832 0 00-1.217-.741c-.254-.077-.52-.122-.774-.177h-.321s-.089.033-.122.033c-.785.078-1.416.42-1.969.974C8.582 4.168 7.431 5.33 6.27 6.49l-.144.144.022.033h.089c.95-.022 1.725.343 2.378 1.018.918.929 1.836 1.836 2.754 2.754.354.343.62.343.963 0l2.82-2.82c.487-.498 1.063-.83 1.77-.919.3-.033.609-.033.93-.055a.583.583 0 00-.1-.122l.011.011zm-2.478-.553c-.354.288-.73.542-1.062.852-.74.72-1.46 1.45-2.179 2.18-.055.055-.11.11-.166.154-1.15-1.029-2.113-2.245-3.352-3.164 0-.022.023-.044.045-.055.74-.741 1.47-1.493 2.223-2.212.63-.609 1.637-.686 2.279-.089.796.73 1.504 1.538 2.256 2.312.023 0 0 .022-.044.044v-.022zM17.598 17.32c-.996.056-1.815-.32-2.511-1.028-.896-.93-1.804-1.814-2.722-2.721-.354-.365-.62-.365-.995 0-.93.929-1.87 1.87-2.799 2.81-.52.52-1.128.851-1.87.918a9.044 9.044 0 01-.586.044c.044.055.067.077.089.1l3.65 3.65c.343.332.73.597 1.195.73.287.088.586.122.874.188h.155c.055-.022.121-.033.177-.044a2.868 2.868 0 001.858-.841c1.239-1.228 2.467-2.467 3.695-3.695.022-.022.033-.055.066-.088h-.276v-.022zm-2.346.908c-.641.652-1.283 1.294-1.935 1.936-.786.785-1.848.785-2.633 0-.686-.675-1.372-1.361-2.047-2.047l-.121-.122c1.194-.907 2.146-2.046 3.208-3.053.033-.033.066-.066.11-.088 0 0 .022 0 .033-.022 1.195 1.05 2.157 2.345 3.496 3.263-.044.044-.088.088-.122.133h.011z"
        fill="currentColor"
      />
    </svg>
  );
}
