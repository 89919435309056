import React, { useCallback, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@hyperlocal/banking-utility";
import { Drawer } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "styled-components";
import { usePrint } from "../../context/Print";
import PrintableQRCode from "../PrintableQRCode";
import SharedDrawer from "../ShareKey";
import * as S from "./styles";
import { MainKeysInfoProps } from "./types";

const MainKeyInfo: React.FC<MainKeysInfoProps> = ({ defaultKey }) => {
  const [isSharePixCodeOpen, setSharePixCodeOpen] = useState(false);
  const { data: QRCode = "", isLoading } = useQuery({
    queryKey: ["pixKeysQrCode", defaultKey?.key],
    queryFn: async () => {
      try {
        const response = await API.pix.post("/api/v1/pix/QrCode/static", {
          key: defaultKey.key,
        });
        return response.data.image as string;
      } catch (error) {
        throw new Error(error);
      }
    },
    refetchOnWindowFocus: true,
    enabled: Boolean(defaultKey),
  });

  const { print } = usePrint();

  const { palette } = useTheme();

  const toggleSharePixDrawer = useCallback(() => {
    if (isLoading) return;

    setSharePixCodeOpen(!isSharePixCodeOpen);
  }, [isSharePixCodeOpen, isLoading]);

  const togglePrintableQRCode = useCallback(() => {
    print({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      content: (
        <PrintableQRCode
          defaultKey={{
            ...defaultKey,
            keyQrCode: QRCode,
          }}
        />
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QRCode]);

  const copyDefaultKey = async () => {
    try {
      await navigator.clipboard.writeText(defaultKey.key);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <S.Container>
        <S.TextWrapper>
          <S.DefaultKeyTitle>Chave Pix de cobrança</S.DefaultKeyTitle>
          <S.DefaultKeyText>{defaultKey.key}</S.DefaultKeyText>
        </S.TextWrapper>
        <S.IconWrapper
          role="button"
          onClick={toggleSharePixDrawer}
          className={isLoading && "cursor-default opacity-30"}
        >
          <Icon name="ComputersShare" color={palette.neutral.darkest} />
        </S.IconWrapper>
      </S.Container>
      <Drawer
        open={isSharePixCodeOpen}
        title="Compartilhar QR Code"
        onDismiss={toggleSharePixDrawer}
        position="right"
      >
        <SharedDrawer
          openImageQrModal={togglePrintableQRCode}
          copyDefaultKey={copyDefaultKey}
        />
      </Drawer>
    </>
  );
};

export default MainKeyInfo;
