import {
  handlePixResponse,
  PixResponse,
} from "@/pages/contestation/api/use-pix-list";
import { useContestationFilter } from "@/pages/contestation/contexts/contestation-filter";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toast } from "@hyperlocal/banking-utility";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const MockedPixResponse: PixResponse = [
  {
    status: "PENDING",
    amount: 235.52,
    transferDate: new Date().toISOString(),
    requestDate: new Date().toISOString(),
    person: "Victor Hyperlocal",
    institution: "NU PAGAMENTOS",
    pixKey: "teste@hyperlocal.app",
    id: "114f46as4f6a4f4a54364455564848787",
    reason: "PENDING_REFOUND",
    installment: "Parcela 5 de 12",
  },
  {
    status: "PENDING",
    amount: 25,
    transferDate: new Date().toISOString(),
    requestDate: new Date().toISOString(),
    person: "Victor Hyperlocal",
    institution: "NU PAGAMENTOS",
    pixKey: "teste@hyperlocal.app",
    id: "214f46as4f6a4f4a54f74455564848787",
    reason: "OVERCHARGE",
    installment: "Parcela 1 de 12",
  },
  {
    status: "CANCELED",
    amount: 1200,
    transferDate: new Date(Date.now() - 86400000).toISOString(), // Ontem,
    requestDate: new Date().toISOString(),
    person: "Victor Hyperlocal",
    institution: "NU PAGAMENTOS",
    pixKey: "teste@hyperlocal.app",
    id: "314f46as4f6a4f4a54f74455564848787",
    reason: "WRONG_AMOUNT",
    installment: "Parcela 1 de 12",
  },
  {
    status: "CANCELED",
    amount: 0,
    transferDate: new Date(Date.now() - 86400000).toISOString(), // Ontem,
    requestDate: new Date().toISOString(),
    person: "Victor Hyperlocal",
    institution: "NU PAGAMENTOS",
    pixKey: "teste@hyperlocal.app",
    id: "414f46as4f6a4f4a54f74455564848787",
    reason: "INCORRECT_PRICE",
    installment: "Parcela 1 de 12",
  },
  {
    status: "CANCELED",
    amount: 0,
    transferDate: new Date(Date.now() - 86400000).toISOString(), // Ontem,
    requestDate: new Date().toISOString(),
    person: "Victor Hyperlocal",
    institution: "NU PAGAMENTOS",
    pixKey: "teste@hyperlocal.app",
    id: "514f46as4f6a4f4a54f74455564848787",
    reason: "INCORRECT_PRICE",
    installment: "Parcela 1 de 12",
  },
  {
    status: "CANCELED",
    amount: 0,
    transferDate: new Date(Date.now() - 86400000).toISOString(), // Ontem,
    requestDate: new Date().toISOString(),
    person: "Victor Hyperlocal",
    institution: "NU PAGAMENTOS",
    pixKey: "teste@hyperlocal.app",
    id: "2614f46as4f6a4f4a54f74455564848787",
    reason: "INCORRECT_PRICE",
    installment: "Parcela 1 de 12",
  },
  {
    status: "CANCELED",
    amount: 0,
    transferDate: new Date(Date.now() - 86400000).toISOString(), // Ontem,
    requestDate: new Date().toISOString(),
    person: "Victor Hyperlocal",
    institution: "NU PAGAMENTOS",
    pixKey: "teste@hyperlocal.app",
    id: "714f46as4f6a4f4a54f74455564848787",
    reason: "INCORRECT_PRICE",
    installment: "Parcela 1 de 12",
  },
  {
    status: "CANCELED",
    amount: 0,
    transferDate: new Date(Date.now() - 86400000).toISOString(), // Ontem,
    requestDate: new Date().toISOString(),
    person: "Victor Hyperlocal",
    institution: "NU PAGAMENTOS",
    pixKey: "teste@hyperlocal.app",
    id: "2124f46as4f6a4f4a54f74455564848787",
    reason: "INCORRECT_PRICE",
    installment: "Parcela 1 de 12",
  },
  {
    status: "CANCELED",
    amount: 0,
    transferDate: new Date(Date.now() - 86400000).toISOString(), // Ontem,
    requestDate: new Date().toISOString(),
    person: "Victor Hyperlocal",
    institution: "NU PAGAMENTOS",
    pixKey: "teste@hyperlocal.app",
    id: "2714f46as4f6a4f4a54f74455564848787",
    reason: "INCORRECT_PRICE",
    installment: "Parcela 1 de 12",
  },
];

async function getPixList() {
  try {
    await new Promise((resolve) => setTimeout(resolve, 250));
    return MockedPixResponse;
  } catch (error) {
    toast.error("Não foi possível buscar as contestações.");
    throw new Error(error);
  }
}

const PIX_LIST_KEY = "get-pix-list";
const CANCEL_PIX_CONTESTATION_MUTATION_KEY = "cancel-pix-contestation";

export function usePixList() {
  const { filterState } = useContestationFilter();

  // startDate e endDate vão ser filtrados via requisição

  return useQuery({
    queryKey: [PIX_LIST_KEY],
    queryFn: () => getPixList(),
    staleTime: 1000 * 30, // 30 segundos (cache entre refetch's)
    select: (pixResponse) => handlePixResponse(pixResponse, filterState),
    refetchOnWindowFocus: true,
    structuralSharing: true,
  });
}

interface CancelPixContestationProps {
  id: string;
}

async function cancelPixContestation({ id }: CancelPixContestationProps) {
  try {
    await new Promise((resolve) => setTimeout(resolve, 250));
  } catch (error) {
    toast.error("Não foi possível buscar as contestações.");
    throw new Error(error);
  }
}

function getPixContestationCanceled(id: string) {
  return (pixList: PixResponse) => {
    const newList = pixList.map((pix) => {
      if (pix.id === id) {
        return { ...pix, status: "CANCELED" } as const;
      }

      return pix;
    });

    return [...newList];
  };
}

export function useHandleCancelPixContestation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [CANCEL_PIX_CONTESTATION_MUTATION_KEY],
    mutationFn: cancelPixContestation,
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries({ queryKey: [PIX_LIST_KEY] });

      const previousPixList = queryClient.getQueryData([PIX_LIST_KEY]);

      queryClient.setQueryData([PIX_LIST_KEY], getPixContestationCanceled(id));

      return { previousPixList };
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData([PIX_LIST_KEY], context?.previousPixList);
    },
    onSettled: async () => {
      await new Promise((resolve) => setTimeout(resolve, 2500));

      queryClient.invalidateQueries({ queryKey: [PIX_LIST_KEY] });
    },
  });
}
