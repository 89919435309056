import {
  State,
  StatusPayload,
} from "@/pages/contestation/contexts/contestation-filter/types";

function handleNormalizeDate(date: Date | string) {
  const parsedDate = new Date(date);
  parsedDate.setHours(0, 0, 0, 0);
  parsedDate.setDate(parsedDate.getDate() + 1);
  return parsedDate;
}

export function handleStartDate(state: State, date: Date | string) {
  const newStartDate = handleNormalizeDate(date);

  if (state.endDate && newStartDate > state.endDate) {
    return {
      ...state,
      dateErrors: {
        startDate: "A data de início deve ser anterior à data de término",
        endDate: undefined,
      },
    };
  }

  return {
    ...state,
    dateErrors: {
      startDate: undefined,
      endDate: undefined,
    },
    selectedRange: undefined,
    startDate: newStartDate,
  };
}

export function handleEndDate(state: State, date: Date | string) {
  const newEndDate = handleNormalizeDate(date);

  if (state.startDate && newEndDate < state.startDate) {
    return {
      ...state,
      dateErrors: {
        startDate: undefined,
        endDate: "A data de término deve ser posterior à data de início",
      },
    };
  }

  return {
    ...state,
    dateErrors: {
      startDate: undefined,
      endDate: undefined,
    },
    selectedRange: undefined,
    endDate: newEndDate,
  };
}

const TODAY = new Date();

export function getStartDate(numberToSubtract: number) {
  const date = new Date(TODAY);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() - numberToSubtract);
  return date;
}

export function getEndDate() {
  const date = new Date(TODAY);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function handleStatus(state: State, payload: StatusPayload): State {
  const addStatus = [...state.status, payload.status];
  const removeStatus = state.status.filter((item) => item !== payload.status);

  return {
    ...state,
    status: payload.checked ? addStatus : removeStatus,
  };
}
