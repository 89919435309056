import styled, { css } from "styled-components";

export const BreadcrumbWrapper = styled.div`
  ${({ theme }) => {
    const { spacings } = theme;
    return css`
      padding: ${spacings.stack.sm} ${spacings.inline.xs} 0px;
    `;
  }}
`;

export const Main = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.desktop(`
    padding: 32px 0 0 0;
  `)}
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 56px);
    gap: 16px;
    & > h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: ${theme.palette.neutral.darkest};
    }
    & > * {
      font-family: "Inter";
      font-style: normal;
    }
    & > div {
      display: flex;
      flex-direction: column;
      gap: 16px;

      & svg {
        width: 34px;
        height: 34px;
        fill: ${theme.palette.neutral.darkest};
      }
      & > nav > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        & > div {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          & > p {
            display: flex;
            flex-direction: column;
            & > span {
              width: 100%;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
            }
          }
          & > p :nth-child(1) {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
          }
        }
        & > svg {
          width: 24px;
          height: 24px;
          fill: ${theme.palette.neutral.darkest};
        }
      }
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.desktop(`
    padding: 32px 0 0 0;
  `)}
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 56px);
    gap: 16px;
    justify-content: space-between;
    & > div {
      & > p {
        font-size: 14px;
        line-height: 125%;
        color: ${theme.palette.neutral.darker};
        margin-bottom: 24px;
        &.error {
          color: ${theme.palette.status.error.errorDefault};
        }
      }
      & > * {
        font-family: "Inter";
        font-style: normal;
      }
      & input {
        box-sizing: content-box;
      }
    }
    & > button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `}
`;

export const InvisiblePrint = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -10;
`;

export const SuccessScreen = styled.div`
  ${({ theme }) => css`
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: calc(100vh - 128px);
    & > * {
      font-family: "Inter";
      font-style: normal;
    }
    & > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: ${theme.spacings.inline.sm};
      & > img {
        width: 165px;
        height: 165px;
      }
      & > div > h3 {
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: ${theme.palette.neutral.darkest};
      }
      & > div > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: ${theme.palette.neutral.dark};
      }
      & > hr {
        width: 100%;
        background-color: ${theme.palette.neutral.light};
      }

      & > svg {
        display: block;
        width: 177.38px;
        height: auto;
      }
    }

    & > div.wrapperButtons {
      display: flex;
      flex-direction: row;
      gap: 8px;
      width: 100%;
      & > button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }
  `}
`;
export const WrapperQRIcon = styled.div`
  ${({ theme }) => css`
    padding: 16px 0 35px 0;
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    & > span {
      margin-bottom: 18px;
    }
    & > nav > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 11px;
        & > div > p {
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
        }
        & > div > h3 {
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
        }
      }
      & svg {
        width: 24px;
        height: 24px;
        fill: ${theme.palette.neutral.darkest};
      }
    }
  `}
`;
export const WrapperClaimDrawer = styled.div`
  ${({ theme }) => css`
    flex-direction: column;
    display: flex;
    gap: 16px;
    max-width: 430px;

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    & > div {
      display: flex;
      gap: 15px;
      margin-top: 8px;
      & > p {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        & > span {
          width: 100%;
          font-weight: 400;
          font-size: 14px;
          line-height: 170%;
        }
      }

      & > svg {
        width: 32px;
        height: auto;
        fill: ${theme.palette.neutral.darkest};
      }
    }
  `}
`;
