import { MyKeysResponse } from "@/components/MyKeys";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Validator } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import { keysTypes } from "../../utils/keyTypes";
import * as Styles from "./KeyCard.styles";

type KeyCardProps = {
  pixKey: MyKeysResponse;
  goToKey: (key: MyKeysResponse) => void;
};

function getTypeIcon(type: string) {
  const foundKeyType = keysTypes.find((key) => key.type === type);
  return foundKeyType ? foundKeyType.icon : keysTypes[0].icon;
}

export function KeyCard({ goToKey, pixKey }: KeyCardProps) {
  const { checkPixKey } = Validator;

  return (
    <Styles.Container key={pixKey?.key}>
      <Styles.Content type="button" onClick={() => goToKey(pixKey)}>
        <Styles.KeyContainer>
          <Icon name={getTypeIcon(pixKey.keyType)} />
          <div>
            <Styles.Title>
              {pixKey.keyType === "PhoneNumber"
                ? checkPixKey(pixKey?.key.replace("+55", ""))
                : checkPixKey(pixKey?.key)}
            </Styles.Title>
            <Styles.Key>
              {pixKey.keyType === "PhoneNumber"
                ? pixKey?.key.replace("+55", "")
                : pixKey?.key}
            </Styles.Key>
          </div>
        </Styles.KeyContainer>
      </Styles.Content>
    </Styles.Container>
  );
}
