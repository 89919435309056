import { MyKeysResponse } from "@/components/MyKeys";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency } from "@hyperlocal/banking-utility";
import { Divider } from "@hyperlocal/vital";
import * as S from "./styles";

interface PrintableQRCodeProps {
  defaultKey: MyKeysResponse & { keyQrCode: string };
  additionalData?: {
    amount?: number;
    description?: string;
    processingDate?: string;
    operationId?: string;
  };
}

const PrintableQRCode: React.FC<PrintableQRCodeProps> = ({
  defaultKey,
  additionalData,
}) => {
  return (
    <>
      <S.ContainerPrint className="printable">
        <header>
          <img
            src={`data:image/png;base64,${defaultKey?.keyQrCode}`}
            alt="qrcode"
          />
          <S.InfoWrapper>
            <S.BoldText>Dados da Chave Pix</S.BoldText>
            <S.OrdinaryInformation>
              Favorecido: {defaultKey.account.name}
            </S.OrdinaryInformation>
            <S.OrdinaryInformation>
              Chave Pix: {defaultKey.key}
            </S.OrdinaryInformation>
            <S.OrdinaryInformation>
              Instituição: 301 - Hyperlocal Bank. SA
            </S.OrdinaryInformation>
          </S.InfoWrapper>
        </header>
        <Divider />

        <S.Blank>
          {!!additionalData?.amount && (
            <S.DataList>
              <S.DataTitle>Valor da Cobrança</S.DataTitle>
              <S.DataDescription>
                {formatCurrency(additionalData?.amount)}
              </S.DataDescription>
            </S.DataList>
          )}
          {!!additionalData?.description && (
            <S.DataList>
              <S.DataTitle>Descrição da cobrança</S.DataTitle>
              <S.DataDescription>
                {additionalData?.description}
              </S.DataDescription>
            </S.DataList>
          )}
          {!!additionalData?.processingDate && (
            <S.DataList>
              <S.DataTitle>Data de processamento</S.DataTitle>
              <S.DataDescription>
                {additionalData?.processingDate}
              </S.DataDescription>
            </S.DataList>
          )}
          {!!additionalData?.operationId && (
            <S.DataList>
              <S.DataTitle>Id da operação</S.DataTitle>
              <S.DataDescription>
                {additionalData?.operationId}
              </S.DataDescription>
            </S.DataList>
          )}
        </S.Blank>

        <S.Footer>
          <Divider />
          <S.WarningParagraph>
            <p>
              Importante: É possível que alguns usuários enfrentem problemas na
              leitura do QRcode. Algumas câmeras podem ter dificuldade para
              conseguir focar na imagem. Aproxime bem a câmera do celular no
              QRCode, mova-o até que a imagem esteja mais nítida.
            </p>
          </S.WarningParagraph>
          <Divider />
        </S.Footer>
      </S.ContainerPrint>
    </>
  );
};

export default PrintableQRCode;
