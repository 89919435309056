import { Fragment } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@hyperlocal/banking-utility";
import { useQuery } from "@tanstack/react-query";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
// import { getAuth } from "@hyperlocal/banking-utility";
// import { usePromise } from "../../../hooks/usePromise";
// import { apis } from "../../../services";
import { groupBy } from "../../../utils/groupBy";
import { TransactionItem } from "./TransactionItem";
import * as S from "./TransactionsList.styles";

function normalizeDate(date: string) {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

const TODAY = new Date().toISOString();

const startDate = normalizeDate(TODAY);
startDate.setDate(startDate.getDate() - 15);

// const endDate = normalizeDate(TODAY);

const startDateMinusOneDay = new Date(TODAY);
startDateMinusOneDay.setDate(startDateMinusOneDay.getDate() - 1);

export function handleDate(
  date: string,
  type: "hour" | "weekday" | "day-month",
) {
  const newDate = new Date(date);
  const isToday = newDate.toDateString() === new Date().toDateString();

  const formatters = {
    hour: newDate.toLocaleTimeString("pt-BR", {
      hour: "numeric",
      minute: "numeric",
    }),
    "day-month": isToday
      ? `Hoje, ${newDate.toLocaleDateString("pt-BR", {
          day: "numeric",
          month: "long",
        })}`
      : newDate.toLocaleDateString("pt-BR", {
          day: "numeric",
          month: "long",
        }),
    weekday: isToday
      ? newDate.toLocaleDateString("pt-BR", {
          weekday: "long",
        })
      : "",
  };

  return formatters[type] || "";
}

type TransactionDetails = {
  accountNumber: string;
  agencyNumber: string;
  bankCode: number;
  ispb: number;
  bankName: string;
  description: string;
  externalId: string;
  idempotencyKey: string;
  name: string;
};

interface Extract {
  amount: number;
  date: string;
  type: string;
  summary: string;
  partnerKey: string;
  operation_type: string;
  details: TransactionDetails;
}

export interface ListItem {
  CreatedAt: string;
  Description: string;
  Id: string;
  Movement: "OUT" | "IN";
  Participant: string | null;
  Type: "Boleto" | "P2P" | "Pix" | "TED";
  Value: number;
}

export const mockedData: Extract[] = [
  {
    amount: 200,
    date: new Date().toISOString(),
    operation_type: "operation_type",
    partnerKey: "+55 11 99999-9999",
    summary: "A flor silvertre que perfuma os campos...",
    type: "type",
    details: {
      accountNumber: "1548544-5",
      agencyNumber: "1234",
      bankCode: 2,
      bankName: "HyperLocal S.A",
      description: "description",
      externalId: "214f46as4f6a4f4a54f74455564848787",
      idempotencyKey: "idempotencyKey",
      ispb: 5,
      name: "Victor",
    },
  },
  {
    amount: -100,
    date: new Date().toISOString(),
    operation_type: "operation_type",
    partnerKey: "+55 11 99999-9999",
    summary: "A flor silvertre que perfuma os campos...",
    type: "type",
    details: {
      accountNumber: "1548544-5",
      agencyNumber: "1234",
      bankCode: 2,
      bankName: "HyperLocal S.A",
      description: "description",
      externalId: "214f46as4f6a4f4a54f744555648487832",
      idempotencyKey: "idempotencyKey",
      ispb: 5,
      name: "Victor",
    },
  },
];

export type ExtractResponse = {
  Balance: number;
  Items: ListItem[];
  Pagination: {
    PreviousPage: number;
    CurrentPage: number;
    NextPage: number;
    Last: boolean;
    TotalPages: number;
  };
};

export async function getExtract() {
  try {
    const response = await API.statements.get<ExtractResponse>("/Extract");
    return response.data;
  } catch (error) {
    // toast.error("Não foi possível buscar as contestações.");
    throw new Error(error);
  }
}

export function usePixList() {
  return useQuery({
    queryKey: ["extract"],
    queryFn: () => getExtract(),
    staleTime: 1000 * 30, // 30 segundos (cache entre refetch's)
    select: (pixResponse) =>
      pixResponse.Items.filter((item) => item.Type === "Pix"),
    refetchOnWindowFocus: true,
  });
}

export function TransactionsList() {
  const { isLoading, data } = usePixList();

  const groupedByDate = groupBy(data, "CreatedAt");

  return (
    <S.List>
      {data ? (
        groupedByDate.map((item) => {
          return (
            <Fragment key={item.key}>
              <S.ListHeading>
                <h5>{handleDate(item?.key, "day-month")}</h5>
                <small>{handleDate(item?.key, "weekday")}</small>
              </S.ListHeading>

              {item.items.map((transaction) => {
                return (
                  <TransactionItem
                    // @ts-ignore
                    key={transaction.Id}
                    // @ts-ignore
                    transaction={transaction}
                  />
                );
              })}
            </Fragment>
          );
        })
      ) : (
        <S.Feedback>
          {isLoading ? "Carregando..." : "Nenhuma transação encontrada"}
        </S.Feedback>
      )}
    </S.List>
  );
}
