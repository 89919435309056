import { Button, Card } from "@hyperlocal/vital";
import styled, { css } from "styled-components";

export const Container = styled(Card)`
  width: 100% !important;
  cursor: pointer;

  & svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.palette.neutral.darkest};
  }
`;

export const Content = styled(Button)`
  ${({ theme }) => css`
    padding: unset;
    border: unset;

    width: 100%;

    background-color: transparent;

    display: flex;
    justify-content: space-between;
    align-items: center;

    text-align: start;

    cursor: pointer;

    :hover,
    :focus {
      background-color: transparent;
    }

    &[aria-busy="true"] {
      justify-content: center;
    }

    &[aria-busy="true"] svg path {
      fill: ${theme.palette.neutral.darker} !important;
      stroke: ${theme.palette.neutral.darker} !important;
    }
  `}
`;

export const KeyContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.inset.x2s};
    width: 100%;

    & > div {
      &[data-default-key="true"] {
        width: 17.125em;
      }
      display: flex;
      flex-direction: column;
      color: ${theme.palette.neutral.darkest};
    }
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.sm};
    font-size: ${theme.fontSizes.x2s};
  `}
`;

export const Key = styled.span`
  ${({ theme }) => css`
    &[data-default-key="true"] {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    font-size: ${theme.fontSizes.xs};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.sm};
  `}
`;
