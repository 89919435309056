import { useState } from "react";
import { formatPhoneNumber } from "@/components/NewKey";
import {
  API,
  globalQueryClient,
  queries,
  useAccountStore,
  Validator,
} from "@/utils";
import { Button, toast, Token } from "@hyperlocal/vital2";
import { Timer } from "./components/Timer";

const { pixKeys } = queries;

type KeyType = ReturnType<typeof Validator.checkPixKey>;

interface TokenSheetProps {
  isExternalCode: boolean;
  keyType: KeyType;
  newPixKey: string;
  goToNextStep: () => void;
  onSendToken: () => void;
  openClaimDrawer: () => void;
}

export const formattedKeyTypes: Partial<Record<KeyType, string>> = {
  "E-mail": "email",
  Celular: "PhoneNumber",
  CNPJ: "nationalRegistration",
  CPF: "nationalRegistration",
};

export function TokenSheet({
  isExternalCode,
  keyType,
  newPixKey,
  goToNextStep,
  onSendToken,
  openClaimDrawer,
}: TokenSheetProps) {
  const [totpCode, setTotpCode] = useState("");
  const { currentAccountId } = useAccountStore();

  async function handleVerification() {
    const formattedPixKey =
      keyType === "Celular" ? `+55${formatPhoneNumber(newPixKey)}` : newPixKey;

    try {
      await API.pix.post("/api/v1/pix/Key", {
        type: formattedKeyTypes[keyType] ?? "evp", // NationalRegistration, Email, PhoneNumber, EVP
        token: totpCode,
        ...(isExternalCode ? { key: formattedPixKey } : {}),
      });

      await globalQueryClient.invalidateQueries({
        queryKey: pixKeys.getKeys({ accountId: currentAccountId }),
      });

      goToNextStep();
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.friendlyMessage ===
        "Entry Key Owned By Different Person"
      ) {
        openClaimDrawer();
        return;
      }

      toast({
        variant: "error",
        title:
          error?.response?.data?.errors?.[0]?.friendlyMessage ||
          "Algo deu errado",
      });
    }
  }

  const isDisabled = totpCode.length !== 6;

  const getContentText = () => {
    if (["E-mail", "Celular"].includes(keyType))
      return `Digite o código que foi enviado via ${keyType === "E-mail" ? keyType : "SMS"}:`;

    return (
      <>
        Digite o código do Token que foi <br /> enviado via{" "}
      </>
    );
  };

  return (
    <div className="flex flex-1 flex-col">
      <h2 className="mt-4 text-xs/md font-bold text-neutral-darkest mobile:text-xs">
        {getContentText()}

        {!isExternalCode && (
          <span className="text-primary-main">aplicativo autenticador</span>
        )}
      </h2>

      {isExternalCode && (
        <h5 className="font-inter text-[20px] font-bold leading-[30px] text-primary-main">
          {newPixKey}
        </h5>
      )}

      <div className="mt-8">
        <Token.Label className="mobile:text-x2s/default">
          Código de 6 caracteres
        </Token.Label>
        <Token.Root onValueChange={setTotpCode}>
          <Token.Input />
          <Token.Input />
          <Token.Input />
          <Token.Input />
          <Token.Input />
          <Token.Input />
        </Token.Root>
      </div>

      {["E-mail", "Celular"].includes(keyType) && (
        <Timer resendToken={() => onSendToken()} />
      )}

      <Button.Root
        variant="primary"
        className="mt-auto w-full justify-center"
        onClick={handleVerification}
        disabled={isDisabled}
      >
        Verificar
      </Button.Root>
    </div>
  );
}
