import { Card } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { useTooltipHandler } from "../../utils/useTooltipHandler";
import { Tooltip } from "../Tooltip";
import * as Styles from "./styles";

const SharedDrawer: React.FC<{
  copyDefaultKey: () => Promise<void>;
  openImageQrModal: () => void;
}> = ({ copyDefaultKey, openImageQrModal }) => {
  const [isShown, handleTooltip] = useTooltipHandler();

  const handleCopy = async () => {
    try {
      await copyDefaultKey();
      handleTooltip();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Styles.WrapperQRIcon>
      <span>Escolha como deseja compartilhar o seu QR Code</span>
      <nav style={{ cursor: "pointer" }} onClick={handleCopy}>
        <Card style={{ position: "relative" }}>
          <Tooltip isShown={isShown} message="Copiado!" />
          <div>
            <Icon name="GeralGeralCopy" />
            <div>
              <p>Para usar com Pix copia e cola</p>
              <h3>Código do QR Code</h3>
            </div>
          </div>
          <Icon name="ArrowArrowNoLineRight" />
        </Card>
      </nav>
      <nav style={{ cursor: "pointer" }} onClick={openImageQrModal}>
        <Card>
          <div>
            <Icon name="GeralGeralCopy" />
            <div>
              <p>Para usar com leitor de QR Code</p>
              <h3>Imagem do QR Code</h3>
            </div>
          </div>
          <Icon name="ArrowArrowNoLineRight" />
        </Card>
      </nav>
    </Styles.WrapperQRIcon>
  );
};

export default SharedDrawer;
