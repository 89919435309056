import { twMerge } from "tailwind-merge";

type FieldInfoProps = {
  label: string;
  value: string;
  statusLabel?: "active";
}

export function FieldInfo({ label, value, statusLabel }: FieldInfoProps) {
  const isActiveStatusLabel = Boolean(statusLabel);

  const commonLabelClassNames = "text-xs leading-7 text-neutral-dark";
  const activeLabelClassNames = "text-sm leading-9 text-primary-main font-bold";

  const valueClassNames = twMerge(
    isActiveStatusLabel ? activeLabelClassNames : commonLabelClassNames
  );

  return (
    <div className="flex justify-between">
      <p className="text-x2s leading-6 text-neutral-darker">{label}</p>

      <p className={valueClassNames}>{value}</p>
    </div>
  );
}