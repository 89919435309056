import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const TextWrapper = styled.div`
  flex: 1;
  gap: 8px;
  display: flex;
  max-width: 100%;
  flex-direction: column;
`;

export const DefaultKeyTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    color: ${theme.palette.neutral.darkest};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.spacings.inset.lg}
  `}
`;

export const DefaultKeyText = styled.h5`
  inline-size: 100%;
  word-break: break-all;

  ${({ theme }) => css`
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.palette.primary.main};
    line-height: ${theme.spacings.inset.xl}
  `}
`;

export const IconWrapper = styled.div`
  width: 100%;
  max-width: 24px;
  cursor: pointer;
`;

export const InvisiblePrint = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -10;
`;
