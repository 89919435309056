import styled, { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @media print {
    *:not(.printable, .printable *, :has(.printable)), .noPrint {
      display: none !important;
    }

    * {
      -webkit-print-color-adjust: exact;
    }

    body, html {
      height: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .printable {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .print-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
`;

const MediaPrintStyles = `
  @media print {
    position: fixed;
    right: 0;
    padding: 0;
    height: auto;
    align-items: center;
    background-color: transparent;
    justify-content: center;

    .printable {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 100%;
    }

    & > section {
      width: auto;
      justify-content: center;
      align-items: center;
    }

    & > img {
      margin: 0;
      width: 168px;
      height: 168px;
    }

  }
`;

export const QRModalImage = styled.div`
  ${({ theme }) => css`
    z-index: 999;
    left: 0;
    padding: 64px 18.5px 18.5px 18.5px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: ${theme.palette.neutral.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-family: ${theme.fonts.base};

    & > section {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      gap: 16px;

      & > h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        width: 100%;
      }

      & > img {
        margin: 34px 0;
        width: 168px;
        height: 168px;
      }

      & > svg {
        width: 150px;
        height: auto;
        margin: 24px 0 32px 0;
      }

      & > hr {
        width: 100%;
        background-color: ${theme.palette.neutral.light};
      }

      & > p {
        display: flex;
        width: 100%;
        justify-content: space-between;

        & > strong {
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
          color: ${theme.palette.neutral.darkest};
        }

        & > span {
          font-weight: 400;
          font-size: 14px;
          color: ${theme.palette.neutral.dark};
          line-height: 170%;
        }
      }
    }

    & > div {
      display: flex;
      width: 100%;
      gap: 8px;

      & > button {
        width: 48%;
        display: flex;
        justify-content: center;
        align-self: center;
      }
    }
  `}

  ${MediaPrintStyles};
`;