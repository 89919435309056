import { lazy, Suspense, useEffect } from "react";
import { PixReceiptDetails } from "@/components";
import { PixKeysProvider } from "@/context/Pix";
import {
  getDashboardRoutesData,
  PixRoutes,
  ProtectedRoute,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Route, Routes as Switch } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { Feedback } from "../components/Dashboard/styles";
import PixDashboard from "../components/PixDashboard";
import { useRoutes } from "../context/routes";

const Charge = lazy(() => import("../components/Charge"));
const MyKeys = lazy(() => import("../components/MyKeys"));
const NewKey = lazy(() => import("../components/NewKey"));
const ShareCharge = lazy(() => import("../components/ShareCharge"));
const Dashboard = lazy(() => import("../components/Dashboard"));
// const Contestation = lazy(() => import("../pages/contestation"));

type RoutesProps = {
  defaultRoutes: PixRoutes;
};

export const Routes = (props: RoutesProps) => {
  const { defaultRoutes } = props;

  const { setRoutes } = useRoutes();

  useEffect(() => {
    setRoutes(defaultRoutes);
  }, [defaultRoutes, setRoutes]);

  return (
    <Suspense fallback={<Feedback>Carregando...</Feedback>}>
      <Switch>
        <Route
          path={defaultRoutes.dashboard}
          element={
            <ProtectedRoute
              allowedRoles={["Proprietário", "Administrador", "Moderador"]}
            >
              <Dashboard handleOpenNewKeysDrawer={() => {}} />
            </ProtectedRoute>
          }
        />

        <Route
          path={defaultRoutes.pix}
          element={
            <ProtectedRoute
              allowedRoles={["Proprietário", "Administrador", "Moderador"]}
            >
              <PixKeysProvider>
                <PixDashboard />
              </PixKeysProvider>
            </ProtectedRoute>
          }
        >
          <Route path=":id" element={<PixReceiptDetails />} />
        </Route>

        <Route
          path={defaultRoutes.pixMyKeys}
          element={
            <ProtectedRoute
              allowedRoles={[
                "Proprietário",
                "Administrador",
                "Moderador",
                "Financeiro",
              ]}
            >
              <MyKeys />
            </ProtectedRoute>
          }
        />

        <Route
          path={defaultRoutes.pixMyKeysNew}
          element={
            <ProtectedRoute
              allowedRoles={["Proprietário", "Administrador", "Moderador"]}
            >
              <NewKey />
            </ProtectedRoute>
          }
        />

        <Route
          path={defaultRoutes.pixCharge}
          element={
            <ProtectedRoute
              allowedRoles={[
                "Proprietário",
                "Administrador",
                "Moderador",
                "Financeiro",
              ]}
            >
              <Charge />
            </ProtectedRoute>
          }
        />

        <Route
          path={defaultRoutes.pixChargeShare}
          element={
            <ProtectedRoute
              allowedRoles={[
                "Proprietário",
                "Administrador",
                "Moderador",
                "Financeiro",
              ]}
            >
              <ShareCharge />
            </ProtectedRoute>
          }
        />

        {/* <Route
          path={defaultRoutes.pixContestation}
          element={<Contestation />}
        /> */}
        <Route
          path={getDashboardRoutesData().transfers}
          element={
            <Parcel
              config={() => System.import("@hyperlocal/banking-transfers")}
              mountParcel={mountRootParcel}
            />
          }
        />
        <Route
          path={getDashboardRoutesData().cashOutPixCopiaCola}
          element={
            <Parcel
              config={() => System.import("@hyperlocal/banking-cash-out")}
              mountParcel={mountRootParcel}
            />
          }
        />
        <Route
          path={getDashboardRoutesData().cashOutBoletoCopiaCola}
          element={
            <Parcel
              config={() => System.import("@hyperlocal/banking-cash-out")}
              mountParcel={mountRootParcel}
            />
          }
        />
        <Route
          path={getDashboardRoutesData().statements}
          element={
            <Parcel
              config={() => System.import("@hyperlocal/banking-statements")}
              mountParcel={mountRootParcel}
            />
          }
        />
      </Switch>
    </Suspense>
  );
};
