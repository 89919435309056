import { PropsWithChildren } from "react";
import { ArrowLeft } from "@/assets/svgs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getPixRoutesData } from "@hyperlocal/banking-utility";
import { BreadCrumbItem } from "@hyperlocal/vital";
import { Link } from "react-router-dom";
import * as Styles from "./Layout.styles";

export interface ILayoutProps {
  title?: string;
  breadcrumb?: string[];
  boxType?: "inner" | "outer";
  onBreadcrumbClick?: () => void;
  isDesktop: boolean;
}

export function Layout({
  children,
  title,
  breadcrumb,
  boxType = "inner",
  onBreadcrumbClick,
  isDesktop,
}: PropsWithChildren<ILayoutProps>) {
  return (
    <Styles.Container>
      <Styles.Content>
        <Styles.Box>
          {isDesktop && (
            <Styles.Box type={boxType}>
              {breadcrumb && (
                <Styles.Breadcrumb onClickIcon={onBreadcrumbClick}>
                  {breadcrumb?.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <BreadCrumbItem active key={index}>
                      {item}
                    </BreadCrumbItem>
                  ))}
                </Styles.Breadcrumb>
              )}

              {title && (
                <Styles.Title>
                  <Link to={getPixRoutesData()?.dashboard}>
                    <ArrowLeft />
                  </Link>

                  {title}
                </Styles.Title>
              )}
            </Styles.Box>
          )}

          {children}
        </Styles.Box>
      </Styles.Content>
    </Styles.Container>
  );
}
