import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.inset.lg};
  gap: ${({ theme }) => theme.spacings.inset.lg};
  background: ${({ theme }) => theme.palette.neutral.white};

  ${({ theme }) =>
    theme.breakpoints.mobile(`
      display: none;
    `)}

  ${({ theme }) =>
    theme.breakpoints.tablet(`
      display: none;
    `)}
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ValueTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    line-height: ${theme.lineHeights.md};
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.palette.neutral.darkest};
  `}
`;
export const Value = styled.b<{ isDebit?: boolean }>`
  ${({ theme, isDebit }) => css`
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.bold};
    line-height: ${theme.lineHeights.md};

    color: ${isDebit
      ? theme.palette.status.error.errorDark
      : theme.palette.primary.main};

    small {
      font-size: ${theme.fontSizes.xs};
      font-weight: ${theme.fontWeights.regular};
      margin-right: ${theme.spacings.inline.x3s};
    }
  `}
`;
