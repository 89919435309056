import { ComponentProps } from "react";

export function Pix(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_327_885)" fill="currentColor">
        <path d="M20.6 22.027l-4.013-4.014a.589.589 0 00-.414-.173.589.589 0 00-.413.173l-4.027 4.027c-.453.453-1.16 1.187-3.52 1.187l4.947 4.933a4 4 0 005.653 0l4.96-4.947c-1.213 0-2.226-.24-3.173-1.186zM11.733 9.96l4.027 4.027a.61.61 0 00.413.173.61.61 0 00.414-.173L20.573 10c.947-.987 2.027-1.213 3.24-1.213l-4.96-4.947a4 4 0 00-5.653 0L8.253 8.773c2.347 0 3.067.774 3.48 1.187z" />
        <path d="M28.147 13.133l-3-3.013h-1.68c-.72 0-1.44.293-1.934.813l-4 4c-.373.374-.866.56-1.36.56a2 2 0 01-1.36-.56l-4.026-4.04c-.507-.506-1.2-.8-1.934-.8h-1.96L3.84 13.16a4 4 0 000 5.653l3.053 3.067h1.974c.72 0 1.413-.293 1.933-.8l4.027-4.027c.373-.373.866-.56 1.36-.56.493 0 .986.187 1.36.56l4.013 4.014c.507.506 1.2.8 1.933.8h1.68l3-3.014c1.56-1.573 1.56-4.133-.026-5.72z" />
      </g>
      <defs>
        <clipPath id="clip0_327_885">
          <path fill="#fff" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
