import { ComponentProps } from "react";

export function CheckLogo(props: ComponentProps<"svg">) {
  return (
    <svg
      width={166}
      height={165}
      viewBox="0 0 166 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx={83} cy={82.5} rx={83} ry={82.5} fill="#CCE1FF" />
      <ellipse
        cx={82.8887}
        cy={82.3896}
        rx={62.416}
        ry={62.04}
        fill="#6694FB"
      />
      <ellipse
        cx={83.1095}
        cy={82.6096}
        rx={40.504}
        ry={40.26}
        fill="#0047F6"
      />
      <path
        d="M70.828 84.046l7.05 6.61a2 2 0 002.914-.191L94.998 73.15"
        stroke="#fff"
        strokeWidth={5}
        strokeLinecap="round"
      />
    </svg>
  );
}
