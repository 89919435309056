import { apis } from "../services";
import { GenerateCorrectDefaultKey } from "./types";

export const generateCorrectDefaultKeyCode = async ({
  accountId,
  defaultKey,
  userId,
}: GenerateCorrectDefaultKey) => {
  return apis.createQR(
    {
      amount: "0",
      key: defaultKey.key,
    },
    accountId,
    userId,
  );
};
